.team-card {
  position: relative;
  z-index: 1;
  background-image: url('../../assets/static/team-card-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: ac(32px, 20px);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  overflow: hidden;

  &.border-gradient {
    --border-radius: ac(32px, 20px);
    --brd-color-1: #6c69b1;
    --brd-color-2: #36d7ce;
    --brd-rotate: 90deg;

    &:after {
      position: absolute;
      opacity: 1;
      transition: 0.3s;
      top: -1px;
      bottom: -1px;
      left: 50%;
      right: -1px;
      border-left-color: transparent;
      border-top-color: transparent;
      border-bottom-color: transparent;
      content: '';
      pointer-events: none;
      z-index: 2;
      background: linear-gradient(
        90deg,
        rgba(51, 51, 51, 0) 0%,
        rgba(51, 51, 51, 1) 100%
      );
    }

    &:hover {
      .border-gradient-bg {
        &:before {
          opacity: 1;
        }
      }

      &:after {
        opacity: 0;
      }
    }
  }

  .border-gradient-bg {
    &::after {
      background: linear-gradient(
        var(--brd-rotate),
        var(--brd-color-1) 0%,
        var(--brd-color-2) 60%,
        rgba(51, 51, 51, 0) 100%
      );
    }

    &:before {
      opacity: 0;
      transition: 0.3s;
    }
  }

  &__image {
    @mixin aspect-ratio 1, 1;
    mask-image: url('../../assets/static/mask/team-card-mask.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    width: 49.82%;
    min-width: 49.82%;
    position: relative;
    z-index: 3;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ac(32px, 20px);
    flex: 1;
    padding-top: 6px;
    position: relative;
    z-index: 3;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__socials {
    padding-top: ac(24px, 16px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    --size: 40px;
    width: var(--size);
    height: var(--size);
    border: 1px solid var(--cyan);
    border-radius: 5px;
    background: transparent;
    color: var(--cyan);
    font-size: 40px;
    transition: 0.3s;

    &:hover {
      background-color: var(--cyan);
      color: var(--pink);
    }
  }

  &__buttons {
    padding-top: ac(28px, 16px);
    transform: translateY(10px);

    .link-btn {
      .arrow-btn {
        width: 16px;
        border-color: transparent;
        background: transparent !important;
      }
    }
  }

  @media (max-width: 490px) {
    flex-direction: column;
    grid-row-gap: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;

    .team-card__image {
      width: 100%;
    }

    .team-card__content {
      padding-left: 0;
      width: 100%;
    }
  }

  @media (max-width: 374px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
}
