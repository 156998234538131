.contact-section {
  background: var(--white);
  color: var(--black);
  padding-top: ac(0px, 20px);
  padding-bottom: ac(80px, 40px);

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 951px) {
      flex-direction: column;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 37.58%;
    margin-top: ac(-5px, 0px);

    &:not(:last-child) {
      margin-bottom: ac(56px, 24px);
    }

    @media (max-width: 951px) {
      width: 100%;
    }
  }

  &__title {
    &.headline-2 {
      line-height: 1.32;
    }
    &:not(:last-child) {
      margin-bottom: ac(16px, 20px);
    }
  }

  &__subtitle {
    font-size: ac(28px, 20px);
    line-height: 128.57%;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: ac(48px, 24px);
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    border-bottom: 1px solid var(--black);
  }

  &__tab {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ac(15px, 14px) ac(32px, 20px);
    cursor: pointer;
    color: var(--black);
    transition: 0.3s;
    font-size: 16px;
    line-height: 26px;

    &.active {
      background-color: var(--black);
      color: var(--white);
    }

    &:hover {
      &:not(.active) {
        color: var(--pink);
      }
    }
  }

  &__forms {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 57.84%;
    margin-top: ac(90px, 24px);

    @media (max-width: 951px) {
      width: 100%;
    }
  }

  &__form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &.active {
      opacity: 1;
      top: auto;
      left: auto;
      position: relative;
      pointer-events: auto;
    }
  }

  &__form-title {
    &.headline-4 {
      line-height: 127.27%;
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__form-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(32px, 16px);
    grid-row-gap: ac(40px, 20px);

    &:not(:last-child) {
      margin-bottom: ac(40px, 20px);
    }

    .form-field-wr {
      span {
        font-size: 14px;
        color: var(--pink);
        opacity: 1;
      }

      input,
      textarea {
        border-color: var(--black);
        color: var(--black);
        @mixin transparent-bg-input var(--black);

        &::placeholder {
          color: var(--gray-text);
        }
      }
    }

    @media (max-width: 451px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 451px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;
    }
  }

  &__form-checkbox {
    width: 48.23%;

    @media (min-width: 1400px) {
      min-width: 380px;
    }

    @media (max-width: 451px) {
      width: 100%;
    }

    .meta-checkbox__text {
      color: var(--gray-text);
      letter-spacing: -0.01em;

      a {
        color: var(--pink);

        &:hover {
          color: var(--black);
        }
      }
    }
  }

  &__form-buttons {
    width: 48.23%;

    @media (min-width: 1025px) {
      max-width: 302px;
    }

    .btn-wr {
      width: 100%;

      .btn-outline {
        width: 100%;
      }
    }

    @media (max-width: 451px) {
      width: 100%;
    }
  }
}
