.not-found {
  min-height: 100vh;
  min-width: 100vw;
  position: relative;

  &__wrapper {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px;

    p {
      margin-bottom: ac(24px, 16px);
      font-size: ac(26px, 20px);
      font-weight: 400;
      text-align: center;

      @media (max-width: 360px) {
        font-size: 18px;
      }
    }
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    margin-bottom: ac(32px, 24px);
    text-align: center;

    @media (max-width: 430px) {
      font-size: 46px;
    }

    @media (max-width: 360px) {
      font-size: 38px;
    }
  }
}