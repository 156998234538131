.teams-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(64px, 40px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(40px, 32px);
    }

    @media (max-width: 551px) {
      br {
        display: none;
      }

      &.headline-4 {
        font-size: ac(38px, 26px);
        line-height: 1.48;

        @media (max-width: 551px) {
          font-size: 22px;
        }

        @media (max-width: 374px) {
          font-size: 20px;
        }

        @media (max-width: 330px) {
          font-size: 18px;
        }
      }
    }
  }

  &__filters {
    &:not(:last-child) {
      margin-bottom: ac(64px, 32px);
    }
  }

  &__result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  &__result-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(24px, 20px);
    width: 100%;

    @media (max-width: 951px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &:not(:last-child) {
      margin-bottom: ac(64px, 32px);
    }
  }
}
