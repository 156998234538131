.blogs-section {
  padding-top: ac(80px, 40px);
  padding-bottom: 40px;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(22px, 20px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(36px, 32px);
    }
  }

  &__filters {
    &:not(:last-child) {
      margin-bottom: ac(64px, 32px);
    }
  }

  &__result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  &__result-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(80px, 40px);
    width: 100%;
    overflow: hidden;

    .resources-card {
      position: relative;
      width: 100%;
      max-width: 100%;

      &:before {
        position: absolute;
        width: 1224px;
        height: 1px;
        background-image: url('../../assets/static/blogs-line.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: calc(ac(80px, 40px) / 2 * -1);
        transform: translateY(50%);
        left: 0;
      }

      @media (min-width: 952px) {
        &:nth-child(3n + 1) {
          &:before {
            content: '';
          }
        }
      }
    }

    @media (max-width: 951px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .resources-card {
        @media (min-width: 552px) {
          &:nth-child(2n + 1) {
            &:before {
              content: '';
            }
          }
        }
      }
    }

    @media (max-width: 551px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));

      .resources-card {
        &:before {
          content: '';
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(64px, 32px);
    }
  }

  + .podcasts-section {
    padding-top: 0;
  }
}
