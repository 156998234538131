.premium-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(64px, 40px);

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 30px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40.69%;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(32px, 30px);
    }
  }

  &__slider {
    width: 55.56%;
    overflow: hidden;
    border-radius: 12px;

    .swiper {
      width: 100%;
      max-width: 100%;

      &:not(.swiper-initialized) {
        .swiper-wrapper {
          display: flex;
          align-items: stretch;
          justify-content: center;
          flex-wrap: wrap;
          grid-column-gap: ac(16px, 12px);
          grid-row-gap: ac(16px, 12px);
        }

        + .swiper-buttons {
          display: none;
        }
      }
    }

    .swiper-buttons {
      .swiper-button {
        margin-top: ac(32px, 24px);
      }
    }

    .premium-section {
      &__item {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        width: calc(33.33% - (ac(16px, 12px) / 1.5));

        &:nth-child(2n) {
          background: rgba(0, 0, 0, 0.2);
        }

        &:before {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) translateX(100%);
          height: 1px;
          width: ac(16px, 12px);
          content: '';
          background: linear-gradient(90deg, #5e5d6c -6.25%, #615e6e 106.25%);
          z-index: -1;

          /*@media (max-width: 1023px) {
            @media (min-width: 641px) {
              width: ac(26px, 16px);
            }
          }*/
        }

        &:after {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateY(100%) translateX(-50%);
          height: ac(16px, 12px);
          width: 1px;
          content: '';
          background: linear-gradient(180deg, #8c365b 0%, #32867f 100%);
          z-index: -1;
        }

        &:last-child {
          &:before {
            display: none;
          }
        }

        @media (max-width: 490px) {
          width: calc(50% - 12px / 2);

          &:last-child {
            &:nth-child(2n + 1) {
              /*width: 100%;*/

              z-index: 5;
              &:before {
                background: var(--black);
                left: -30px;
                right: -30px;
                top: 0;
                transform: translateY(-100%);
                width: auto;
                height: 12px;
                display: block;
                content: '';
              }

              &:after {
                bottom: auto;
                top: 0;
                height: calc(50% + 12px);
                transform: translateY(-100%) translateX(-50%);
              }
            }
          }
        }
      }
    }

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: ac(23px, 20px);
    padding-bottom: 19px;
    padding-left: ac(23px, 16px);
    padding-right: ac(23px, 16px);
    position: relative;
    cursor: default;
    height: auto;

    &.border-gradient {
      --border-radius: 12px;
      --brd-color-1: #34ded0;
      --brd-color-2: #f0428d;
    }
  }

  &__item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    --size: ac(40px, 36px);
    width: var(--size);
    height: var(--size);
    background: var(--pink);
    border-radius: 50%;
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    i {
      font-size: 15px;
      color: var(--black);
    }
  }

  &__item-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 52px;
  }

  &__text {
    &.subtitle-1 {
      font-size: ac(20px, 16px);
      line-height: 1.7;
    }
  }

  &.candidates-page {
    .premium-section__slider {
      position: relative;
      overflow: visible;
      width: 43.56%;

      .swiper {
        &:not(.swiper-initialized) {
          .swiper-wrapper {
            grid-column-gap: 0px;
            grid-row-gap: 0px;
          }

          + .swiper-buttons {
            display: none;
          }
        }
      }
    }

    .premium-section__item-image {
      transition: 0.3s;
    }

    .premium-section__item-title {
      white-space: nowrap;
    }

    .premium-section__item {
      justify-content: flex-end;
      background: none;
      border: none;
      border-radius: 0;
      border-color: var(--cyan) !important;
      padding: ac(44px, 38px) ac(38px, 32px);

      &:hover {
        .premium-section__item-image {
          transform: scale(1.1);
        }
      }

      &:before {
        content: none;
      }

      &:after {
        content: none;
      }

      &:nth-child(1) {
        .premium-section__item-image {
          @mixin aspect-ratio 1, 1;
          width: 49px;
        }
      }

      &:nth-child(2) {
        .premium-section__item-image {
          @mixin aspect-ratio 44, 49;
          width: 44px;
        }
      }

      &:nth-child(3) {
        .premium-section__item-image {
          @mixin aspect-ratio 97, 49;
          width: 97px;
        }
      }

      &:nth-child(4) {
        .premium-section__item-image {
          @mixin aspect-ratio 1, 1;
          width: 49px;
        }
      }

      &:nth-child(5) {
        .premium-section__item-image {
          @mixin aspect-ratio 96, 48;
          width: 96px;
        }
      }

      &:nth-child(6) {
        .premium-section__item-image {
          @mixin aspect-ratio 87, 44;
          width: 87px;
        }
      }

      &:nth-child(7) {
        .premium-section__item-image {
          @mixin aspect-ratio 1, 1;
          width: 47px;
        }
      }

      &:nth-child(8) {
        .premium-section__item-image {
          @mixin aspect-ratio 40, 73;
          width: 40px;
        }
      }

      &:nth-child(9) {
        .premium-section__item-image {
          @mixin aspect-ratio 87, 41;
          width: 87px;
        }
      }


      &:nth-child(1),
      &:nth-child(4) {
        border-right: 1px solid;
        border-bottom: 1px solid;
      }

      &:nth-child(2) {
        border-right: 1px solid;
        border-bottom: 1px solid;
      }

      &:nth-child(3) {
        border-bottom: 1px solid;
      }

      &:nth-child(5) {
        border-bottom: 1px solid;
        border-right: 1px solid;
      }

      &:nth-child(6) {
        border-bottom: 1px solid;
      }

      &:nth-child(7),
      &:nth-child(8) {
        border-right: 1px solid;
      }
    }

    .premium-section__bg {
      position: absolute;
      inset: 0;
      z-index: -1;
      min-width: 730px;
      min-height: 633px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media (max-width: 1200px) {
      .premium-section__slider {
        width: 55%;
      }
    }

    @media (max-width: 1023px) {
      .premium-section__slider {
        width: 100%;
      }
    }

    @media (max-width: 490px) {
      .premium-section__item {
        &:nth-child(2) {
          border-right: none;
        }

        &:nth-child(3) {
          border-right: 1px solid;
        }

        &:nth-child(4) {
          border-right: none;
        }

        &:nth-child(7) {
          border-bottom: 1px solid;
        }

        &:nth-child(8) {
          border-right: none;
          border-bottom: 1px solid;
        }

        &:nth-child(9) {
          border-right: 1px solid;
          border-left: 1px solid;
          width: calc(50% - 6px);
        }
      }
    }
  }
}
