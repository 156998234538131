.text-list-section {
  overflow: visible;
  padding-top: ac(80px, 40px);
  padding-bottom: 40px;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      grid-row-gap: 24px;
    }
  }

  &__bg {
    @mixin aspect-ratio 928.69, 771.96;
    position: absolute;
    top: ac(-124px, -50px);
    right: ac(-295px, -80px);
    width: 75.82%;

    img {
      object-fit: contain;
    }

    @media (max-width: 768px) {
      top: auto;
      bottom: -80px;
    }
  }

  &__content {
    padding-top: ac(64px, 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 49.67%;

    @media (max-width: 768px) {
      width: 100%;
      padding-top: 0;
    }
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__text {
    color: #F3F3F3;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 43.79%;
    padding: ac(66px, 24px);

    &.border-gradient {
      --border-radius: ac(30px, 16px);
      --brd-color-1: #fb3f90;
      --brd-color-2: #36d7ce;
      --brd-rotate: -90deg;
    }

    &:before {
      position: absolute;
      content: '';
      inset: ac(16px, 8px);
      border-radius: ac(20px, 12px);
      background: linear-gradient(
        149.57deg,
        #333333 2.71%,
        rgba(51, 51, 51, 0.9) 96.46%
      );
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__list-title {
    width: 100%;
    padding-bottom: ac(19px, 14px);
    border-bottom: 1px solid var(--gray);
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      margin-bottom: ac(20px, 14px);
    }
  }

  &__list-text {
    position: relative;
    z-index: 1;

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: ac(16px, 12px);

      li {
        position: relative;
        font-size: ac(16px, 15px);
        line-height: ac(26px, 22px);
        font-weight: 400;
        padding-left: ac(42px, 28px);

        &:before {
          mask-image: url('../../assets/static/mask/check-fill.svg');
          mask-size: 100% 100%;
          mask-repeat: no-repeat;
          --size: ac(26px, 22px);
          width: var(--size);
          height: var(--size);
          background: var(--pink);
          position: absolute;
          top: 0;
          left: 0;
          content: '';
        }
      }
    }
  }
}
