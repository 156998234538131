.download-section {
  margin-top: ac(60px, 40px);
  margin-bottom: ac(60px, 40px);
  position: relative;
  overflow: hidden;

  &__bg {
    position: absolute;
    inset: -2px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding-top: ac(42px, 24px);
    padding-bottom: ac(40px, 24px);

    .download-section__buttons {
      z-index: 10;
    }

    @media (max-width: 640px) {
      flex-direction: column;
      grid-row-gap: 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 49.18%;

    @media (max-width: 640px) {
      width: 100%;
      padding-top: 16px;
    }
  }

  &__title {
    width: 100%;
    max-width: ac(500px, 250px);

    &:not(:last-child) {
      margin-bottom: ac(39px, 24px);
    }

    &.headline-3 {
      line-height: 125%;
    }
  }

  &__text {
    font-size: ac(20px, 16px);
    line-height: 1.7;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__images {
    --arw: 399.1;
    --arh: 486.82;
    position: relative;
    width: 40%;
    max-width: 399px;
    margin-right: ac(56px, 0px);

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
    }

    @media (max-width: 640px) {
      margin-top: 20px;
      width: 100%;
    }

    &[data-view] {
      .download-section__image {
        opacity: 0;
        transition: 0.6s;
      }

      .download-section__image-bg {
        opacity: 0;
        transform: rotate(0deg);
        transition: 0.6s;
        transition-delay: 0.15s;
      }

      &.animated {
        .download-section__image-bg {
          opacity: 1;
          transform: rotate(15deg);
        }

        .download-section__image {
          opacity: 1;
        }
      }
    }
  }

  &__image {
    @mixin aspect-ratio 299.65, 423.7;
    position: absolute !important;
    z-index: 2;
    top: 6.48%;
    left: 7.61%;
    box-shadow: 0px 0px ac(80px, 50px) rgba(0, 0, 0, 0.25);
    width: 75.08%;
    overflow: visible;
    mask-image: none;

    &.border-gradient {
      --border-radius: 0;
      --brd-color-1: #6c69b1;
      --brd-color-2: #36d7ce;

      .border-gradient-bg {
        z-index: 1;
      }
    }
  }

  &__image-bg {
    @mixin aspect-ratio 299.65, 423.7;
    position: absolute;
    z-index: 1;
    top: 6.48%;
    left: 12.46%;
    transform: rotate(15deg);
    box-shadow: 0px 0px ac(80px, 50px) rgba(0, 0, 0, 0.25);
    width: 75.08%;
    overflow: visible;
    mask-image: none;
  }

  &.peta-page {
    .download-section__title {
      max-width: 100%;
    }
  }
}
