/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: ac(28px, 13px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  .logo {
    img {
      &:last-child {
        display: none;
      }
    }
  }

  &.contact-us {
    .logo {
      img {
        &:last-child {
          display: none;
        }
      }
    }

    &.scrolled {
      .menu-toggle {
        div {
          span {
            background: var(--cyan);
          }
        }
      }

      .logo {
        img {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: block;
          }
        }
      }
    }
  }

  &.scrolled {
    transform: none;
    padding: 10px 0;
    &:not(.open-menu) {
      padding: 10px 0;
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);

      &:before {
        opacity: 0.5;
        background-color: var(--black);
        will-change: backdrop-filter;
      }

      .logo {
        width: 190px;

        @mixin max-xs {
          width: 165px;
        }

      }
    }
    .logo{
        transform: scale(0.8);
      &:hover{
        transform: scale(0.9);
      }
    }
    .menu-toggle{
      width: 50px;
      height: 50px;
    }
    .btn-outline{
      min-height: ac(54px, 44px);
      color: var(--cyan);
      border-color: var(--cyan);
    }
    .menu-toggle.absolute {
      div{
        --bg: var(--cyan)
      }
      &:hover{
        div{
          --bg:var(--white)
        }
      }
    }
  }
  .menu-toggle, .btn-outline{
    transition: 0.3s;
  }

  &.scrolled-hide{
    transform: translateY(-100%);
  }

  .logo {
    height: 100%;
    width: ac(191px, 162px);
    flex-shrink: 0;
    transition: 0.3s;
    z-index: 100;
    margin-right: auto;
    position: relative;
    &:hover {
      transform: scale(1.06);
    }

    img {
      width: 100%;
    }


    @mixin max-xs {
      width: 180px;
    }
  }
  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;

    .btn-wr {
      position: relative;

      .btn {
        --clip-point: 0px;
        transition: color 0.3s, background 0.3s;
        clip-path: polygon(
                0 0,
                calc(100% - var(--clip-point) + 0.5px) 0,
                calc(100% - var(--clip-point) + 0.5px) 100%,
                0 100%
        );

      }
      .btn-white {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        clip-path: polygon(
                calc(100% - var(--clip-point)) 0,
                100% 0,
                100% 100%,
                calc(100% - var(--clip-point)) 100%
        );
      }
      &:hover {
        .btn {
          background: var(--purple);
          color: var(--white);
        }
      }
      .btn-outline{
        --clip-point: 0px;
        clip-path: polygon(
                0 0,
                calc(100% - var(--clip-point) + 0.5px) 0,
                calc(100% - var(--clip-point) + 0.5px) 100%,
                0 100%
        );
        transition: color 0.3s, background 0.3s, border 0.3s;
        &.white{
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          clip-path: polygon(
                  calc(100% - var(--clip-point)) 0,
                  100% 0,
                  100% 100%,
                  calc(100% - var(--clip-point)) 100%
          );
        }
      }
    }
  }
}




/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

/* ----------------------- HEADER COMPONENTS ----------------------- */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.header.header-mobile {
  .burger {
    display: flex;
  }
}

/* end of ONLY Mobile HEADER */

.menu-toggle-wr {
  position: relative;
  z-index: 3;
  &.active {
    background: var(--white);
    .menu-toggle {
      div {
        --bg: var(--black);
        span {
          top: 50%;
          width: 100%;
          transform: translate(-50%, -50%) rotate(-45deg);

          &:nth-of-type(2) {
            opacity: 0;
            transform: none;
            width: 0;
          }

          &:nth-of-type(3) {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }
  &:hover {
    .menu-toggle {
      background: var(--cyan);
    }
  }
}
.menu-toggle {
  position: relative;
  width: ac(64px, 48px);
  height: ac(64px, 48px);
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  border: 2px solid var(--cyan);
  justify-content: center;
  transition: background 0.3s;
  z-index: 1;
  --clip-point: 0px;
  clip-path: polygon(
    0 0,
    calc(100% - var(--clip-point)) 0,
    calc(100% - var(--clip-point)) 100%,
    0 100%
  );

  div {
    --bg: var(--black);
    position: relative;
    width: calc(100% - ac(20px, 10px));
    height: 16px;

    span {
      display: block;
      position: absolute;
      left: 50%;
      height: 2px;
      background: var(--bg);
      transform: translate(-50%, -50%);
      width: calc(100% - 15px);
      transition: transform 0.3s;

      &:nth-of-type(1) {
        top: 0;
        transform: translate(-50%, 0);
      }

      &:nth-of-type(2) {
        width: 100%;
        top: 50%;
      }

      &:nth-of-type(3) {
        bottom: 0;
        transform: translate(-50%, 0);
      }
    }
  }

  &.absolute {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    clip-path: polygon(
      calc(100% - var(--clip-point)) 0,
      100% 0,
      100% 100%,
      calc(100% - var(--clip-point)) 100%
    );
    div {
      --bg: var(--white);
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}



/* end of HEADER COMPONENTS */

@mixin mobile-menu-start-point {
  .header {
    /*position: absolute !important;*/
    .btn-wr{
      display: none;
    }
  }
  .menu-toggle {
    background: var(--white);
    div {
      --bg: var(--black) !important;
    }
  }
}

