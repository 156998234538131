.yourself-section {
  &__wrapper {
    padding-top: ac(80px, 40px);
    padding-bottom: ac(80px, 40px);
  }

  &__block {
    display: flex;
    min-height: 700px;
    position: relative;
    padding: ac(16px, 12px);
    z-index: 1;
    border-radius: ac(30px, 20px);
    overflow: hidden;
  }

  &__image-container {
    position: absolute;
    inset: 0;
    z-index: -1;

    &:before {
      position: absolute;
      z-index: 5;
      border-radius: ac(20px, 16px);
      border: 1px solid rgba(0, 0, 0, 0.4);
      content: '';
      inset: ac(16px, 12px);
    }
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__bg-image {
    position: absolute;
    inset: 0;
    z-index: 1;

    &:nth-child(1) {
      z-index: 1;
    }

    &:nth-child(2) {
      z-index: 2;
      mix-blend-mode: multiply;
    }
  }

  &__image {
    position: absolute;
    inset: ac(16px, 12px);
    mix-blend-mode: hard-light;
    overflow: hidden;
    border-radius: ac(20px, 16px);
    z-index: 2;
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.8) 10%,
      rgba(0, 0, 0, 0.2) 60%,
      rgba(0, 0, 0, 0) 100%
    );

    /*&:before {
      position: absolute;
      content: '';
      border-radius: ac(20px, 12px);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      z-index: 1;
      inset: 0;
      mix-blend-mode: luminosity;
    }*/

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  &__item {
    display: flex;
    align-items: flex-end;
    position: relative;
    min-height: 100%;
    z-index: 5;
    padding: ac(48px, 24px) ac(64px, 24px);
  }

  &__item-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }

  &__left {
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: ac(40px, 20px);
  }

  &__content {
    position: relative;
    z-index: 5;
    width: 62.63%;

    p {
      &:not(:last-child) {
        margin-bottom: ac(12px, 8px);
      }
    }
  }

  &__button {
    z-index: 5;
  }

  @media (max-width: 768px) {
    .yourself-section__item-wrapper {
      flex-direction: column;
      gap: 30px;
    }

    .yourself-section__content {
      width: 100%;
    }
  }
}
