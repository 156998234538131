.main-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    height: calc(var(--dvh, 1vh) * 100);
    background: var(--black);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transform: translate(100%, 0);
    transition: transform 0.5s;
    will-change: transform;

    .logo {
        transition: transform 0.3s;
        will-change: transform;

        &:hover {
            transform: scale(1.1);
        }
    }

    &.active {
        transform: none;
    }

    .cont {
        padding-left: ac(53px, 20px);
        padding-right: ac(25px, 20px);
    }

    &-header {
        padding: 30px 0;

        .cont {
            display: block;
            position: relative;
        }

        .navbar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: ac(32px, 22px);

            .btn-wr {
                position: relative;
                margin-left: auto;
            }
        }

        .menu-close {
            width: ac(48px, 38px, 1024, 1440);
            height: ac(48px, 38px, 1024, 1440);
            position: absolute;
            right: ac(-19px, -7px);
            top: 50%;
            transform: translate(100%, -45%);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid transparent;
            transition: color 0.3s, border-color 0.3s;
            font-size: ac(22px, 20px, 1024, 1440);
            will-change: color, border-color;

            &:hover {
                color: var(--pink);
                border-color: var(--pink);
            }
        }

        .btn-outline {
            min-height: ac(53px, 48px);
            min-width: ac(180px, 120px);
        }
    }

    .menu-lines {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: -1;
        pointer-events: none;
        min-width: 860px;
    }

    &-title-wr {
        display: flex;
        align-items: center;
    }

    &-title {
        display: flex;
        flex-direction: column;
        gap: ac(40px, 0px);
        color: var(--white);
        font-size: ac(86px, 45px);
        line-height: 1.5;
        font-weight: bold;
        padding: 20px 0 30px;

        a {
            display: inline-block;
            transform: translateX(-150px);
            opacity: 0;
            transition: transform 1s 0.5s, opacity 1s 0.5s, color 0.3s 0s;
            will-change: transform, opacity, color;

            &:nth-of-type(2) {
                transition: transform 1s 0.7s, opacity 1s 0.7s, color 0.3s 0s;
            }

            &:hover {
                color: var(--cyan);
            }

            &.active {
                color: var(--cyan);
            }
        }
    }

    .navbar-nav {
        flex-grow: 1;
        border-top: 1px solid var(--white);
        width: 100%;
        display: flex;

        .cont {
            display: grid;
            grid-template-columns: 1fr ac(315px, 225px);
            grid-template-rows: 1fr auto;
        }
    }

    .menu-wr {
        padding: 12px 0 12px ac(60px, 20px);
        border-left: 1px solid var(--white);
        display: flex;
        align-items: center;

        [data-simplebar] {
            width: 100%;
            max-height: 100%;
            overflow-x: hidden;
        }
    }

    .menu {
        display: flex;
        flex-direction: column;
        gap: ac(17.6px, 14px);
    }

    .menu-item {
        position: relative;
        transform: translateY(-15px) rotate(-5deg);
        clip-path: polygon(0 90%, 100% 50%, 100% 90%, 0 100%);
        display: inline-block;
        opacity: 0;
        transition: clip-path 0.8s, transform 0.8s, opacity 0.8s;
        will-change: clip-path, transform, opacity;


    }

    .menu-link {
        position: relative;
        line-height: 1.5;
        font-weight: 600;
        transition: colo 0.5s ease;
        cursor: pointer;
        padding-bottom: 2px;
        font-size: 27px;

        &.active {
            color: var(--cyan);

            &:before {
                width: 100%;
            }
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background: var(--cyan);
            transition: all 0.3s ease;
            will-change: width;
        }

        &:hover {
            color: var(--cyan);

            &::before {
                width: 100%;
            }
        }
    }

    &.active {
        .main-menu {
            &-title {
                a {
                    transform: none;
                    opacity: 1;
                }
            }

            &-footer-contact,
            &-footer-address {
                transform: none;
                opacity: 1;
                transition-delay: 1500ms;
            }

            &-footer-contact {
                transition-delay: 1700ms;
            }
        }

        .social-btn {
            transform: none;
            opacity: 1;
            transition: transform 0.8s 1.9s, opacity 1s 1.9s, color 0.3s 0s;
            @media (max-width: 650px) {
                transition: transform 0.8s 1.2s, opacity 1s 1.2s, color 0.3s 0s;
            }
        }

        .menu-item {
            opacity: 1;
            transform: none;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition-delay: 500ms;


            &:nth-of-type(2) {
                transition-delay: 600ms;
            }

            &:nth-of-type(3) {
                transition-delay: 700ms;
            }

            &:nth-of-type(4) {
                transition-delay: 800ms;
            }

            &:nth-of-type(5) {
                transition-delay: 900ms;
            }

            &:nth-of-type(6) {
                transition-delay: 1000ms;
            }

            &:nth-of-type(7) {
                transition-delay: 1100ms;
            }

            &:nth-of-type(8) {
                transition-delay: 1200ms;
            }
        }
    }

    &-footer {
        position: relative;
        display: flex;
        gap: ac(62px, 20px);
        padding: 26px 20px 44px 0;

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100vw;
            right: 0;
            top: 0;
            height: 1px;
            background: var(--white);
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
            color: var(--pink);
        }

        a {
            transition: color 0.3s;
            font-size: 16px;
            line-height: 1.5;

            &:hover {
                color: var(--cyan);
            }
        }

        &-address {
            transform: translateY(20px);
            opacity: 0;
            transition: 1s;
        }

        &-contact {
            display: flex;
            flex-direction: column;
            gap: 18px;
            transform: translateY(20px);
            opacity: 0;
            transition: 1s;
        }
    }

    .social-buttons {
        display: flex;
        align-items: center;
        padding: 23px 20px 20px ac(66px, 20px);
        gap: 28px;
        line-height: 1;
        position: relative;
        border-left: 1px solid var(--white);

        .btn-outline {
            display: none;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100vw;
            left: 0;
            top: 0;
            height: 1px;
            background: var(--white);
        }
    }

    .social-btn {
        font-size: ac(48px, 32px);
        color: var(--white);
        transform: translateY(150px);
        opacity: 0;

        &:hover {
            color: var(--cyan);
        }
    }
}

@media (min-width: 1200px) {
    @media (max-height: 790px) {
        .main-menu-title {
            font-size: 56px;
            gap: 20px;
        }

        .main-menu .menu-link {
            font-size: 30px;
        }

        .main-menu.active .social-btn {
            font-size: 38px;
        }
    }
}

@media (min-width: 992px) {
    @media (max-height: 790px) {
        .main-menu {
            .menu {
                gap: 0;
            }

            &-footer {
                padding-bottom: 20px;
            }
        }
    }
    @media (max-height: 700px) {
        .main-menu {
            .menu-link {
                font-size: 30px;
            }

            &-title {
                font-size: 66px;
                gap: 0;
            }

            .social-btn {
                font-size: 38px;
            }
        }
    }
}

@media (max-width: 768px) {
    .main-menu-header .cont {
        padding-right: 60px;

        .menu-close {
            right: 10px;
            transform: translate(0, -50%);
        }
    }
}

@media (max-width: 650px) {
    .main-menu {
        .cont {
            padding-left: 36px;
        }

        &-header {
            .cont {
                padding-left: 36px;

                .menu-close {
                    font-size: 34px;
                    right: 40px;
                    transform: translate(0, -50%);
                }
            }

            .btn-outline {
                display: none;
            }


        }

        &-footer {
            display: none;
        }

        &-title-wr {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 49px;
        }

        &-title {
            font-size: ac(35px, 30px, 320, 375);
            line-height: 1.7;
            position: relative;
            padding-bottom: 34px;
            width: 100%;

            &::before {
                content: '';
                display: block;
                position: absolute;
                height: 1px;
                width: 150vw;
                left: 50%;
                bottom: 0;
                transform: translate(-50%);
                background: var(--white);
            }
        }

        .logo {
            width: 146px;
        }

        .navbar-nav .cont {
            grid-template-columns: 1fr;
        }

        .menu-wr {
            border: 0;
            padding: 21px 0;
        }

        .menu {
            align-items: flex-start;
            gap: 19.4px;

            &-link {
                font-size: 28px;
            }

            &-lines {
                min-width: 1142px;
                left: -178px;
                bottom: -158px;
            }

            .btn-outline {
                display: none;
            }
        }

        .social-buttons {
            border: none;
            padding: 0 0 44px;
            justify-content: flex-start;
            gap: 17px;

            &::before {
                display: none;
            }

            .btn-outline {
                display: inline-flex;
                min-height: 32px;
                font-size: 11px;
                padding: 5px 26px;
                margin-right: 4px;
                opacity: 0;
                transform: translateY(20px);

            }


            &::before {
                width: 150vw;
                left: -50px;
            }
        }

        .social-btn {
            color: var(--cyan);

            &:hover {
                color: var(--white);
            }
        }
        &.active{
            .social-buttons .btn-outline{
                transition-delay: 1100ms;
                opacity: 1;
                transform: none;
            }
        }
    }

    @media (max-height: 760px) {
        .main-menu {
            &-title-wr {
                padding-top: 0;
            }

            &-title {
                padding: 10px 0;
            }
        }
    }
    @media (max-height: 690px) {
        .main-menu {
            .menu {
                gap: 10px;
            }

            .social-buttons {
                padding-bottom: 20px;
            }
        }
    }


    @media (max-height: 650px) {
        .main-menu .menu-link {
            font-size: 24px;
        }
    }

    @media (max-height: 590px) {
        .main-menu {
            &-title {
                line-height: 1.3;
            }

            .menu {
                gap: 5px;
            }
        }
    }
    @media (max-height: 540px) {
        .main-menu .menu-wr [data-simplebar] {
            max-height: 165px;
        }
    }
    @media (max-height: 460px) {
        .main-menu .menu-wr [data-simplebar] {
            max-height: 108px;
        }
    }
    @media (max-height: 410px) {
        .main-menu {
            &-title {
                font-size: 25px;
            }

            .menu-wr [data-simplebar] {
                max-height: 108px;
            }
        }
    }
    @media (max-height: 380px) {
        .main-menu .menu-wr [data-simplebar] {
            max-height: 70px;
        }
    }
    @media (max-height: 340px) {
        .main-menu-header {
            padding: 20px 0;
        }
    }
}

@media (max-width: 450px) {


    .main-menu .menu-item {
        &:last-of-type {
            display: block;
        }
    }
}
