.form-section {
  padding-top: ac(120px, 40px);
  padding-bottom: ac(87px, 40px);
  overflow: visible;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__decor {
    @mixin aspect-ratio 1359, 1113;
    width: 111.03%;
    left: -27px;
    max-width: 1359px;
    top: ac(-301px, 100px);
    position: absolute;
    z-index: -1;

    img {
      object-fit: contain;
    }
  }

  &__content {
    width: 40.77%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__title {
    &.headline-3 {
      line-height: 125%;
    }
  }

  &__form {
    padding-top: ac(112px, 32px);
    width: 57.6%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__form-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(32px, 16px);
    grid-row-gap: ac(40px, 20px);

    &:not(:last-child) {
      margin-bottom: ac(40px, 20px);
    }

    .form-field-wr {
      span {
        font-size: 14px;
        color: var(--cyan);
        opacity: 1;
      }
    }

    @media (max-width: 451px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 451px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;
    }
  }

  &__form-checkbox {
    width: 48.23%;

    @media (max-width: 451px) {
      width: 100%;
    }
  }

  &__form-buttons {
    width: 48.23%;

    .btn-wr {
      width: 100%;

      .btn-outline {
        width: 100%;
      }
    }

    @media (max-width: 451px) {
      width: 100%;
    }
  }
}
