.filters-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-color: var(--cl-navy);

  &__icon {
    width: ac(16px, 14px);
    height: ac(16px, 14px);

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      font-size: 16px;
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      &.icon-close {
        opacity: 0;
        color: var(--cl-navy);
      }
    }
  }

  &.active {
    .filters-btn__icon {
      i.icon-close {
        opacity: 1;
      }

      i.icon-filters {
        opacity: 0;
      }
    }
  }

  &:not(.active) {
    &:hover {
      .filters-btn__icon {
        i {
          color: var(--white);
        }
      }
    }
  }

  &:hover {
    .filters-btn__icon {
      transform: none;
    }
  }
}

.jobs {
  padding-bottom: ac(80px, 40px);
  margin-top: ac(-200px, -40px);
  overflow: visible;
  position: relative;
  z-index: 20;

  &-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &-subtitle {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    flex-direction: row;
  }

  &-sidebar {
    flex: 1;
    max-width: 24.83%;
    @media (max-width: 1300px) {
      width: 26%;
    }
  }
  &-sidebar-inner {
    padding-top: ac(120px, 32px);
    .btn-close__icon {
      transition: 0.3s all;
    }
    .btn-close__icon:hover {
      opacity: 0.85;
    }
  }
  &-sidebar-filters-title {
    width: 100%;
    margin-bottom: ac(40px, 24px);
    position: relative;
    font-weight: 400;
    font-size: ac(38px, 32px);
    line-height: 1.47368;
    color: #f3f3f3;
    .btn-close {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 5;
      @media (min-width: 641px) {
        display: none !important;
      }
    }
  }

  &-sidebar-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: ac(32px, 24px);

    .btn-show-hide {
      margin-top: ac(12px, 10px);
    }
  }

  &-sidebar-item-content {
    /*padding-top: 6px;*/
    transition: max-height 0.4s ease, margin-top 0.3s ease, opacity 0.3s ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    /*grid-column-gap: 10px;
    grid-row-gap: 9px;*/
    overflow: hidden;

    &.show-hide-content {
    }

    &.with-inputs {
      grid-row-gap: ac(12px, 10px);
      overflow: visible;

      .meta-select,
      .meta-input {
        width: 100%;
      }
    }
  }

  &-sidebar-accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: 8px;
    transition: min-height 0.4s ease;
  }

  &-sidebar-accordion-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    transition: all 0.4s ease;
    overflow: hidden;
    &.active {
      .jobs-sidebar-accordion-item-top {
        margin-bottom: ac(23px, 18px);
      }

      .jobs-sidebar-accordion-item-btn {
        transform: scaleY(-1);
      }

      .jobs-sidebar-accordion-item-content {
        opacity: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;

      .jobs-sidebar-accordion-item-content {
        max-height: 0 !important;
      }

      .jobs-idebar-accordion-item-btn {
        transform: scaleY(1);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(23px, 18px);
    }
  }

  &-sidebar-accordion-item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding-bottom 0.4s ease, margin 0.4s ease, opacity 0.3s ease;
    margin-bottom: 0;
    position: relative;
    padding-bottom: 14px;
    cursor: pointer;
    border-bottom: 1px solid #adadad;
    &:hover {
      opacity: 0.71;
      @media (min-width: 768px) {
        .jobs-sidebar-accordion-item-title {
          transform: translateX(4px);
        }
      }
    }
  }

  &-sidebar-accordion-item-title {
    max-width: calc(100% - 40px);
    color: var(--cl-navy);
    font-size: ac(24px, 20px);
    line-height: 132%;
    font-weight: 500;
    transition: 0.27s all ease-in-out;
  }

  &-sidebar-accordion-item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.4s ease;
    position: relative;
    i {
      font-size: 28px;
      transition: all 0.4s ease;
      color: #69a9a6;
      z-index: 1;
    }

    &:hover {
      i {
        color: var(--cyan);
      }
    }
  }

  &-sidebar-accordion-item-content {
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /*max-height: 0;*/
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.4s ease;
    width: 100%;

    .meta-checkbox {
      min-height: ac(25px, 24px);
      &:hover {
        .meta-checkbox__box {
          border-color: var(--cyan-second);
        }
        .meta-checkbox__text {
          /* color: var(--cl-navy); */
        }
      }
      &:not(:last-child) {
        margin-bottom: ac(11px, 8px);
      }

      &.is-hidden {
        display: none;
      }
    }
  }

  &-sidebar-accordion-item-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(1px, 3px);
    width: 100%;

    .meta-checkbox__text {
      transform: translateY(2px);
      color: #fff;
    }
    .meta-checkbox__box {
      border-color: var(--cyan-second);
      min-width: ac(22px, 16px);
      width: ac(22px, 16px);
      height: ac(22px, 16px);
      i {
        color: var(--black) !important;
        font-size: 11px;
      }
    }
    .meta-checkbox input:checked ~ .meta-checkbox__box {
      background: var(--cyan);
      border-color: var(--cyan);
    }
    .meta-checkbox:hover {
      .meta-checkbox__box {
        border-color: var(--cyan);
        background: transparent;
      }
    }

    .meta-select {
      width: 100%;
    }

    &.jobs-sidebar-salary,
    &.jobs-sidebar-radius {
      display: flex;
      flex-direction: row;
      gap: ac(20px, 12px);
      .meta-select {
        margin-top: 0 !important;
        .choices__item {
          font-size: 14px !important;
        }
      }
      .meta-select .choices[data-type*='select-one']:after,
      .meta-select .choices[data-type*='select-multiple']:after {
        margin-top: -7px !important;
        color: var(--cyan);
      }
    }
  }

  &-sidebar-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ac(12px, 10px);
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0;
      .btn__text {
        margin-right: 0;
      }
    }
  }

  .jobs-top-wrap {
    width: 68.6%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 20;

    &:not(:last-child) {
      margin-bottom: ac(70px, 32px);
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &-result {
    width: 68.6%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    @media (max-width: 1300px) {
      width: 67%;
    }

    @media (max-width: 640px) {
      position: relative;
    }
  }

  &-title {
    margin-bottom: ac(40px, 32px);
    color: var(--cyan);
  }

  &__subtitle {
    margin-top: ac(-4px, 0px);
    font-size: ac(24px, 20px);
    color: var(--cl-navy);
    line-height: 1.32;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &-filters {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: ac(20px, 18px);
    row-gap: ac(24px, 20px);
    max-width: ac(810px, 800px);
    flex-wrap: wrap;
    .meta-input {
      width: 100%;
      max-width: ac(340px, 299px, 1200);
      @media (max-width: 1200px) {
        width: 50%;
        max-width: none;
      }
      @media (max-width: 740px) {
        width: 100%;
      }
      input {
        height: ac(50px, 48px);
      }
    }
    .meta-select {
      width: 100%;

      input {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 1.25 !important;
      }

      &:nth-child(2) {
        max-width: ac(180px, 160px, 1200);
      }
      &:nth-child(3) {
        max-width: ac(249px, 174px, 1200);
        /* width: 31%; */
      }
      &:nth-child(4) {
        max-width: ac(290px, 270px, 1330);
        /* width: 35.8%; */
      }
      @media (max-width: 1200px) {
        width: calc(50% - ac(20px, 18px));
        max-width: none !important;
      }
      @media (max-width: 740px) {
        width: 100%;
      }
    }
    .meta-range {
      width: 100%;
      /* width: 36%; */
      max-width: ac(291px, 270px, 1330);
      @media (max-width: 1330px) {
        width: 48%;
        max-width: none;
      }
      @media (max-width: 740px) {
        width: 100%;
      }
      input {
        width: ac(206px, 180px);
      }
    }
    button {
      display: flex;
      align-items: center;
      min-height: ac(56px, 48px);
      padding: 5px ac(39px, 32px);
      @media (max-width: 1200px) {
        width: calc(50% - ac(20px, 18px));
        max-width: none !important;
      }
      @media (max-width: 740px) {
        width: 100%;
      }
      .btn__icon {
        margin-left: 12px;
        display: flex;
        i {
          color: var(--cyan);
        }
      }
    }
    .filters-btn {
      display: none;
      cursor: pointer;
      width: 100%;
      @media (max-width: 640px) {
        display: flex;
      }
    }
  }

  &-result-bg {
    display: none;
    @media (max-width: 640px) {
      display: block;
      position: absolute;
      inset: 0;
      z-index: 10;
      pointer-events: none;
    }
  }

  &-result-grid {
    padding-top: ac(64px, 28px);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ac(24px, 16px);
    grid-row-gap: ac(42px, 20px);

    @media (max-width: 901px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__filters-btn {
    @media (min-width: 641px) {
      display: none !important;
    }
  }

  &-pagination {
    margin-top: ac(64px, 48px);
  }
  @mixin max-xl {
    &-sidebar {
      max-width: 30%;
    }
  }

  @mixin max-md {
    &-sidebar {
      max-width: 40%;
    }

    &-result {
      width: 55%;
    }
  }

  @mixin max-sm {
    overflow: hidden;

    &-container {
      flex-direction: row;
    }

    &-sidebar {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      opacity: 0;
      max-width: 280px;
      z-index: 2;
      transition: all 0.4s ease;

      @mixin media 374 {
        max-width: 100%;
      }
    }

    &-result {
      width: 100%;
      transition: all 0.3s ease;
      transform: translateX(0);
      opacity: 1;
      position: relative;
    }

    &__filters-btn {
      display: flex;
      margin-right: auto;
      margin-bottom: 24px;
    }

    &-container {
      &.active {
        .jobs-sidebar {
          position: relative;
          opacity: 1;
          transform: translateX(0);
        }

        .jobs-result {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateX(305px);
          opacity: 0.4;

          & > * {
            pointer-events: none;
          }

          @mixin media 374 {
            transform: translateX(110%);
          }
        }

        .jobs-result-bg {
          pointer-events: auto;
        }
      }
    }
  }
}
