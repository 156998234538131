.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 32px;
  min-height: ac(56px, 48px);
  border: 1px solid var(--light-gray);
  color: var(--light-gray);
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 16px;
  will-change: background, color;

  span {
    display: inline-block;
    margin-right: 9px;
  }

  &.btn-black {
    color: var(--black);
    border-color: var(--black);
  }

  &.btn-white-bg {
    background: var(--white);
    color: #000;
  }

  &:hover {
    background: var(--purple);
    color: var(--light-gray);
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ac(26px, 16px);
}

.arrow-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ac(44px, 40px);
  height: ac(44px, 40px);
  font-size: ac(20px, 16px);
  border: 1px solid #ffffff;
  transition: background 0.3s;
  will-change: background;

  i {
    display: inline-block;
    transition: color 0.3s;
    will-change: color;
  }

  &:hover {
    background: var(--cyan);
  }

  &.arrow-btn-large {
    width: ac(56px, 40px);
    height: ac(56px, 40px);
  }
}

.link-btn {
  display: inline-flex;
  gap: 16px;
  align-items: center;
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s;
  position: relative;
  transform: translate(0);
  will-change: color;

  span {
    flex-shrink: 0;
    pointer-events: none;
  }

  .arrow-btn {
    i {
      transition: all 0.2s;
    }
  }

  &:hover {
    color: var(--cyan);

    .arrow-btn {
      color: #ffffff;
      background: var(--cyan);
    }

    &:not(.disabled-cursor) {
      .arrow-btn {
        color: #ffffff;
        background: var(--cyan);

        i {
          transition: none;
          position: absolute;
          left: var(--x);
          top: var(--y);
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &.link-btn-pink {
    gap: 16px;

    .arrow-btn {
      background: var(--pink);
      border-color: var(--pink);
      color: var(--black);
    }

    &:hover {
      .arrow-btn {
        background: var(--cyan);
        border-color: var(--cyan);
        color: var(--white);
      }
    }
  }

  .arrow-btn {
    pointer-events: none;
  }
}

.link-btn-simple {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  transition: 0.3s;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: fit-content;
  will-change: color;

  &:hover {
    color: var(--purple);
  }
}

.btn-show-hide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 8px;
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-charcoal);
  cursor: pointer;
  transition: color 0.3s ease;

  &__icon {
    border: 1px solid var(--cl-charcoal);
    width: ac(38px, 32px);
    height: ac(38px, 32px);
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;

    i {
      font-size: ac(16px, 14px);
      color: var(--cl-charcoal);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: opacity 0.3s ease;
    }
  }

  &__show {
    display: flex;
  }

  &__hide {
    display: none;
  }

  &:not(.is-visible) {
    opacity: 0;
    display: none;
  }

  &:not(.active) {
    .btn-show-hide__icon {
      i.icon-plus-second {
        opacity: 1;
      }
    }
  }

  &.active {
    .btn-show-hide__show {
      display: none;
    }

    .btn-show-hide__hide {
      display: flex;
    }

    .btn-show-hide__icon {
      i.icon-plus-second {
        opacity: 0;
      }
    }
  }

  &:hover {
    color: var(--cl-navy);
  }
}

@media (max-width: 374px) {
  .arrow-btn {
    width: 35px !important;
    height: 35px !important;
    font-size: 14px !important;
  }

  .btn {
    padding: 5px 12px;
  }

  .btn-outline {
    padding: 5px 25px;
  }
}

.btn-outline {
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  position: relative;
  border: 1px solid var(--light-gray);
  padding: 5px 32px;
  min-height: ac(58px, 48px);
  transition: 0.5s ease-in-out;
  border-color: var(--black);
  color: var(--black);
  will-change: border-color;
  font-size: ac(16px, 14px);
  flex-shrink: 0;

  .svg-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      path {
        transition: 0.3s;
      }
    }
  }

  &:hover {
    border-color: rgba(51, 51, 51, 0.1) !important;
  }

  &-inner {
    & + i {
      margin-left: 9px;
      display: inline-block;
    }

    & + .svg-icon {
      margin-left: 9px;
      display: inline-block;
    }
  }

  &.white {
    border-color: var(--white);
    color: var(--white);

    &:hover {
      border-color: rgba(255, 255, 255, 0.1);
    }
  }

  &.white-bg {
    background: var(--white);
    border-color: var(--white);
    color: var(--black);

    &.border-black-color {
      border-color: var(--black);
    }
  }

  &.apply-btn {
    background: var(--white);
    color: var(--black);

    &:hover {
      background-color: #34DDD0FF !important;
    }
  }

  .line-1,
  .line-3 {
    will-change: height, background;
  }

  .line-2,
  .line-4 {
    will-change: width, background;
  }
}

.btn-outline:hover .btn-outline-inner {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.btn-outline:hover .line-1 {
  animation: outline1 0.5s forwards ease-in-out;
}

.btn-outline:hover .line-2 {
  animation: outline2 0.5s forwards ease-in-out;
}

.btn-outline:hover .line-3 {
  animation: outline3 0.5s forwards ease-in-out;
}

.btn-outline:hover .line-4 {
  animation: outline4 0.5s forwards ease-in-out;
}

.btn-outline {
  &:hover {
    &.white-bg {
      border-color: rgba(255, 255, 255, 0.1);
      background-color: rgba(51, 51, 51, 0.2);
      color: var(--white);

      .svg-icon {
        svg {
          path {
            fill: var(--white);
          }
        }
      }

      &.border-black-color {
        border-color: transparent;
        background: transparent;

        color: var(--pink);
      }
    }
  }
}

.btn-outline-clip-wr:hover {
  .btn-outline {
    border-color: rgba(51, 51, 51, 0.1);

    &.white {
      border-color: rgba(255, 255, 255, 0.1);
    }

    &.white-bg {
      border-color: rgba(255, 255, 255, 0.1);
      background-color: rgba(51, 51, 51, 0.2);
      color: var(--white);

      &.border-black-color {
        border-color: transparent;
        background: transparent;

        color: var(--pink);
      }
    }
  }

  .btn-outline .line-1 {
    animation: outline1 0.5s forwards ease-in-out;
  }

  .btn-outline .line-2 {
    animation: outline2 0.5s forwards ease-in-out;
  }

  .btn-outline .line-3 {
    animation: outline3 0.5s forwards ease-in-out;
  }

  .btn-outline .line-4 {
    animation: outline4 0.5s forwards ease-in-out;
  }
}

.line-1 {
  content: '';
  display: block;
  position: absolute;
  width: 2px;
  background-color: var(--cyan);
  left: 0;
  bottom: 0;
}

.line-2 {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  background: linear-gradient(
    to right,
    var(--cyan),
    var(--pink) 84.66%,
    rgba(240, 66, 41, 0.3) 96.07%
  );
  left: 0;
  top: 0;
}

.line-3 {
  content: '';
  display: block;
  position: absolute;
  width: 2px;
  background-color: var(--cyan);
  right: 0;
  top: 0;
}

.line-4 {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  background: linear-gradient(
    to left,
    var(--cyan),
    var(--pink) 84.66%,
    rgba(240, 66, 41, 0.3) 96.07%
  );
  right: 0;
  bottom: 0;
}

.btn-cyan {
  background: var(--cyan);
  border-color: var(--cyan);
  .btn__text {
    color: var(--black);
  }
  &:hover {
    background: rgba(52, 221, 207, 0.7);
  }
}

@keyframes outline1 {
  0% {
    height: 100%;
    bottom: 0;
  }
  65% {
    height: 0;
    bottom: 100%;
  }
  65.0001% {
    height: 0;
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(240, 66, 41, 0.3) 4%,
      var(--pink),
      var(--cyan)
    );
  }
  100% {
    height: 50%;
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(240, 66, 41, 0.3) 4%,
      var(--pink),
      var(--cyan)
    );
  }
}

@keyframes outline2 {
  0% {
    width: 0;
    left: 0;
  }
  75% {
    width: 100%;
    left: 0;
  }
  75.0001% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 15%;
    width: 85%;
  }
}

@keyframes outline3 {
  0% {
    height: 100%;
    top: 0;
  }
  65% {
    height: 0;
    top: 100%;
  }
  65.0001% {
    top: 0;
    height: 0;
    background: linear-gradient(
      to bottom,
      rgba(240, 66, 41, 0.3) 4%,
      var(--pink),
      var(--cyan)
    );
  }
  100% {
    height: 50%;
    top: 0;
    background: linear-gradient(
      to bottom,
      rgba(240, 66, 41, 0.3) 4%,
      var(--pink),
      var(--cyan)
    );
  }
}

@keyframes outline4 {
  0% {
    width: 0;
    right: 0;
  }
  75% {
    width: 100%;
    right: 0;
  }
  75.0001% {
    right: 0;
    width: 100%;
  }
  100% {
    width: 85%;
    right: 15%;
  }
}
