.text-image {
  position: relative;
  z-index: 5;
  overflow: hidden;

  &__wrapper {
    padding-top: ac(96px, 1px);
    padding-bottom: ac(120px, 80px);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    z-index: 5;
  }

  &__title {
    width: 100%;
    margin-bottom: ac(32px, 24px);

    span {
      color: var(--cyan);
    }
  }

  &__left {
    width: 50%;
  }

  &__right {
    position: relative;
    width: 39.40%;
    border-top: 1px solid #333333;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: -3px -2px 32px 0px #00000052;

    &:before {
      position: absolute;
      content: "";
      inset: 1px;
      background: rgba(255, 255, 255, 0.15);
      z-index: 10;
      mix-blend-mode: screen;
    }
  }

  &__image {
    @mixin aspect-ratio 498, 616;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: relative;
    padding: ac(40px, 30px);
    border: 1px solid var(--pink);
    border-radius: ac(20px, 16px);
    background: #333333;
    overflow: hidden;

    img {
      position: absolute;
      bottom: -60%;
      right: -30%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }

  &__content {
    p {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    span {
      color: var(--pink);
    }
  }

  &__bg {
    @mixin aspect-ratio 933, 776;
    position: absolute;
    width: 73.81%;
    top: 50%;
    transform: translateY(-50%);
    right: ac(-250px, -100px);
  }

  @media (max-width: 990px) {
    .text-image__wrapper {
      flex-direction: column-reverse;
      gap: 40px;
      align-items: flex-start;
    }

    .text-image__left {
      width: 100%;
    }

    .text-image__right {
      width: 60%;
    }

    .text-image__bg {
      transform: none;
      top: 120px;
      min-width: 300px;
    }
  }

  @media (max-width: 580px) {
    .text-image__right {
      width: 100%;
    }
  }
}