.promises {
  overflow: hidden;
  z-index: 15;

  &__wrapper {
    padding-top: ac(80px, 40px);
    padding-bottom: ac(96px, 40px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__left {
    width: 47.47%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 100%;
    transition: 0.3s;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    padding-top: 83px;
    padding-bottom: 83px;
    transition: 0.3s;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
    }

    img {
      transition: 0.3s;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: cover;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__border {
    display: block;
    align-self: stretch;
    min-height: 100%;
    width: 1px;
    background: var(--gray);
    color: var(--gray);
  }

  &__right {
    width: 44.01%;
  }

  &__subtitle {
    color: var(--pink);
    font-weight: 400;
    line-height: 162.5%;
    margin-bottom: ac(24px, 20px);
  }

  &__title {
    margin-bottom: ac(32px, 24px);

    span {
      color: var(--cyan);
    }
  }

  &__content {
    margin-bottom: ac(48px, 30px);

    span {
      color: var(--pink);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__block {
    display: flex;
    flex-direction: row;
    gap: ac(32px, 16px);
  }

  &__button {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    display: flex;
    align-items: center;
    gap: ac(16px, 10px);
  }

  @media (max-width: 1200px) {
    .promises__item {
      img {
        max-width: 70%;
      }
    }
  }

  @media (max-width: 1024px) {
    .promises__item {
      img {
        max-width: 70%;
      }
    }
  }

  @media (max-width: 990px) {
    .promises__wrapper {
      flex-direction: column-reverse;
      gap: 50px;
    }

    .promises__left {
      width: 100%;
    }

    .promises__right {
      width: 100%;
    }
  }

  &.product-page {
    .promises {
      &__wrapper {
        padding-top: ac(71px, 40px);
        padding-bottom: ac(97px, 40px);
      }
    }
  }
}
