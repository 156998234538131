/* ../../assets/ */

@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?3nlg8l');
  src:  url('../../assets/fonts/icomoon.eot?3nlg8l#iefix') format('embedded-opentype'),
  url('../../assets/fonts/icomoon.ttf?3nlg8l') format('truetype'),
  url('../../assets/fonts/icomoon.woff?3nlg8l') format('woff'),
  url('../../assets/fonts/icomoon.svg?3nlg8l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\e91c";
}
.icon-prev-15:before {
  content: "\e90e";
}
.icon-next-15:before {
  content: "\e90f";
}
.icon-pause:before {
  content: "\e910";
}
.icon-play-second:before {
  content: "\e911";
}
.icon-search:before {
  content: "\e912";
}
.icon-chevron:before {
  content: "\e913";
}
.icon-phone-card:before {
  content: "\e914";
}
.icon-linked-in-card:before {
  content: "\e915";
}
.icon-mail-card:before {
  content: "\e916";
}
.icon-arrow-prev:before {
  content: "\e917";
}
.icon-arrow-next:before {
  content: "\e918";
}
.icon-play:before {
  content: "\e919";
}
.icon-check-second:before {
  content: "\e91a";
}
.icon-check:before {
  content: "\e91b";
}
.icon-close:before {
  content: "\e90c";
}
.icon-twitter-x:before {
  content: "\e900";
}
.icon-eye:before {
  content: "\e90d";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-currency:before {
  content: "\e905";
}
.icon-download:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-linked-in:before {
  content: "\e908";
}
.icon-location:before {
  content: "\e909";
}
.icon-permanent:before {
  content: "\e90a";
}
.icon-twitter:before {
  content: "\e90b";
}

