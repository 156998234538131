.hero {
  padding-top: 280px;
  display: flex;
  align-items: center;
  padding-bottom: 113px;
  min-height: calc(var(--vh, 1vh) * 100);
  @media (max-height: 760px) {
    padding-top: 110px;
  }

  .cont {
    position: relative;
    z-index: 1;
  }

  &-caption {
    max-width: 612px;
    margin-left: auto;
    text-align: right;
    margin-right: ac(141px, 50px, 992, 1440);
  }

  &-title {
    margin-bottom: ac(40px, 23px);
    letter-spacing: -0.624px;
    line-height: 1.39;
  }

  &-motto {
    font-size: ac(26px, 20px);
    margin-bottom: ac(48px, 45px);
  }
}

.hero-gradient-wr {
  width: 50%;
  border-radius: 0 0 150px 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.hero-gradient {
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
  }
}

.hero-lottie-wr {
  position: absolute;
  left: 0;
  /*bottom: ac(-437px, -302px);*/
  top: 0;
  bottom: 0;
  z-index: 1;
  width: ac(1500px, 900px, 992, 1440);
  min-width: 898px;


  &::before {
    content: '';
    display: block;
    padding-top: 83.13%;
  }
}
.hero-video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @mixin max-tab {
    height: 100lvh;
    transform: translate(-4%, 0);
    width: 100%;
  }

  @mixin max-sm {
    transform: translate(-19%, 0);
    width: 100%;
  }
}

.hero-lines {
  position: absolute;
  top: -26%;
  left: -3%;
  /*background: url("../../assets/images/hero-lines.svg") no-repeat center / cover;*/
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-mask-image: url('../../assets/static/hero-lines-1.svg');
  mask-image: url('../../assets/static/hero-lines-1.svg');
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  display: none;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    z-index: 2;
    background: linear-gradient(208deg, #f12c81 23.96%, #0fd5d8 89.36%);
    mix-blend-mode: color-dodge;
  }
}

.hero-lottie {
  display: block;
  position: absolute;
  top: -26.1%;
  left: -3%;
  width: 100%;
  height: 100%;
  z-index: 1;

  .lottie-play {
    position: absolute;
    inset: 0;
  }
}

.hero-logo {
  position: absolute;
  width: perc(132);
  right: ac(630px, 14px, 1440, 2560);
  bottom: 21px;
  max-width: 132px;
  -webkit-mask-image: url('../../assets/static/softweb-logo-mask.svg');
  mask-image: url('../../assets/static/softweb-logo-mask.svg');
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  &-img {
    img {
      width: 100%;
      height: auto;
    }
  }
  &-lottie {
    width: ac(1504px, 1260px, 992, 1440);
    position: absolute;
    top: ac(-1754px, -1630px, 992, 1440);
    left: ac(-1340px, -1030px, 992, 1440);
    &::before {
      content: '';
      display: block;
      padding-top: 83.13%;
    }
  }

  @media (max-width: 1024px) {
    width: 8.2%;
  }
  &-video {
    position: absolute;
    height: auto;
    transform: translate(0, 0);
    width: ac(1504px, 1140px, 992, 1440);
    top: ac(-510px, -500px, 992, 1440);
    left: ac(-1270px, -988px, 992, 1440);

    video {
      width: 100%;
    }
    @media (max-width: 992px) {
      transform: none;
    }
  }
}

.hero-home {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: var(--white);
  z-index: 0;
  color: var(--black);
  height: calc(var(--vh, 1vh) * 100);

  &.absolute {
    position: absolute;
    margin-top: 100vw;
  }
}

@mixin mobile-menu-start-point {
  .hero-title {
    letter-spacing: -0.3px;
  }

  .home-page {
    padding-top: 0 !important;
  }

  .hiring-section {
    position: relative !important;
    transform: none !important;
    margin-top: 0;
  }

  .hero.hero-home {
    position: relative;
    margin-top: 0;
    height: auto;
    min-height: calc(var(--vh, 1vh) * 100);
    padding-top: 166px;
    padding-bottom: 40px;
    color: var(--white);

    .btn-outline {
      border-color: var(--white);
      width: 375px;
      max-width: 100%;
      color: var(--white);
    }
  }

  .hero-gradient-wr {
    width: 100%;
    border-radius: 0;
  }

  .hero-caption {
    margin-right: 0;
  }

  .hero-logo {
    display: none;
  }
}
@media (max-width: 992px) {
  .hero-lottie-wr {
    max-width: 243%;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 0;
  }
}

@media (max-width: 768px) {
}

@media only screen and (max-width: 450px) and (max-height: 560px) {
  .hero.hero-home {
    padding-top: 100px;
  }
}
