.work-section {
  &.candidates-page {
    .work-section__top {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      margin-bottom: ac(64px, 33px);
    }

    .work-section__left {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 33.36%;

      h2 {
        text-align: left;
        margin: 0;
      }
    }

    .work-section__right {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: 19.80%;
      gap: ac(24px, 14px);

      .btn-outline {
        width: 100%;

        span:first-child {
          white-space: nowrap;
        }
      }

      &-content {
        text-align: right;
        width: 98%;

        span {
          color: var(--pink);
        }

        @media (max-width: 376px) {
          font-size: 15px;
        }

        @media (max-width: 360px) {
          font-size: 12px;
        }
      }
    }

    @media (max-width: 1200px) {
      .work-section__left {
        width: 37%;
      }

      .work-section__right {
        width: 31%;
      }
    }

    @media (max-width: 960px) {
      .work-section__left {
        width: 42%;
      }

      .work-section__right {
        width: 31%;
      }
    }

    @media (max-width: 768px) {
      .work-section__left {
        width: 50%;
      }

      .work-section__right {
        width: 40%;
      }
    }

    @media (max-width: 590px) {
      .work-section__top {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      .work-section__left {
        width: 100%;
      }

      .work-section__right {
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;

        &-content {
          text-align: left;
        }

        .btn {
          width: 100%;
        }
      }
    }
  }
}