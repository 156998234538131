.count {
  z-index: 5;
  position: relative;

  &__wrapper {
    padding-top: ac(80px, 40px);
    padding-bottom: ac(64px, 40px);
    position: relative;
    z-index: 15;
  }

  &__title {
    margin-bottom: ac(64px, 32px);
    text-align: center;
  }

  &__slider {
    z-index: 15;

    .swiper {
      width: 100%;
      overflow: visible;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 36px 32px;
    gap: ac(24px, 20px);
  }

  &__image {
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--cyan);
    border-radius: 50%;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }

  &__bg {
    position: absolute;
    left: 50%;
    width: 100%;
    max-width: 1440px;
    transform: translateX(-50%);
    z-index: -1;
    top: -122px;
    bottom: -56px;
    mask-image: linear-gradient(
      to bottom,
      rgba(3, 3, 3, 0) 0%,
      rgba(3, 3, 3, 1) 10%,
      rgba(3, 3, 3, 1) calc(100% - 50px),
      rgba(3, 3, 3, 0) 100%
    );

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: 991px) {
    .count__slider {
      .swiper-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
      }

      .swiper-slide {
        width: 31.31%;

        @media (max-width: 1200px) {
          width: 45%;
        }
      }
    }
  }
}
