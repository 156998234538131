.our-sectors-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(80px, 40px);
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(56px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    .swiper {
      width: 100%;
      max-width: ac(1038px, 900px);
      position: relative;
      overflow: visible;

      &:before {
        position: absolute;
        left: 50%;
        width: 100vw;
        height: 1px;
        background: var(--pink);
        content: '';
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      @media (max-width: 551px) {
        max-width: 280px;
      }
    }

    .swiper-buttons {
      display: none !important;
    }

    @media (min-width: 768px) {
      .swiper-buttons {
        display: none;
      }
    }

    @media (max-width: 767px) {
      .swiper-buttons {
        display: none;

        .swiper-button {
          margin-top: ac(32px, 24px);
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid var(--pink);
    padding: ac(25px, 24px) ac(31px, 20px);
    background: var(--black);
    overflow: hidden;
    height: auto;
    cursor: default;

    &:after {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(50%) translateX(50%);
      border-radius: 50%;
      content: '';
      background: #f0428d;
      opacity: 0.6;
      filter: blur(100px);
      --size: ac(105px, 80px);
      width: var(--size);
      height: var(--size);
      transition: 0.4s;
    }

    &:hover {
      &:after {
        transform: translateY(50%) translateX(-100%);
        opacity: 0.7;
      }

      .our-sectors-section__item-title {
        &.text-cyan {
          color: var(--pink);
        }
      }
    }
  }

  &__item-title {
    text-align: center;
    position: relative;
    z-index: 1;
    transition: 0.3s;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__item-text {
    text-align: center;
    position: relative;
    z-index: 1;
  }
}
