.article-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(108px, 40px);
  overflow: visible;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }

  &__content {
    width: 68.46%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 768px) {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: ac(32px, 28px);
      }
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ac(48px, 24px);
  }

  &__share {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(24px, 16px);

    .social-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__share-title {
    font-size: ac(24px, 18px);
    line-height: 1.1;
    font-weight: 400;
  }

  &.article-job-details {
    .article-section__text {
      h2:has(+ ul) {
        margin-bottom: ac(42px, 28px);
      }
      ul {
        margin-bottom: ac(34px, 16px);
      }
    }
    .article-section__content {
      @media (max-width: 820px) {
        width: 100%;
      }
    }
    .article-section {
      &__wrap {
        display: flex;
        gap: 20px;
        @media (max-width: 820px) {
          flex-direction: column;
          gap: 38px;
        }
      }
      &__bottom {
        width: 100%;
      }
      &__buttons {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &__apply {
        padding: 5px ac(120px, 80px);
        @media (max-width: 460px) {
          width: 100%;
        }
        &:hover {
          background: var(--blue);
        }
      }
    }
  }
}
