.fp-watermark {
  display: none;
}
html {
  scrollbar-width: thin;
  scrollbar-color: var(--purple) transparent;
  background: var(--black);
  color: var(--light-gray);
  font-family: var(--font-main);
  font-size: var(--fz);
  line-height: (--line-height);
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  overflow-x: hidden;
  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--light-gray);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--purple);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: clip;

  @media (max-width: 1023px) {
    overflow: hidden;
  }
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1264px;
  width: perc(1264);
  padding: 0 20px;

  @mixin max-lg {
    width: 89.33%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 100vw;
  }
}
.text-cyan {
  color: var(--cyan);
}
.text-pink {
  color: var(--pink);
}

section {
  position: relative;
}

section,
header,
footer {
  max-width: 100vw;
}

#bold-credits {
  display: block;
  margin: ac(45px, 35px) auto 0;
  position: relative;
  z-index: 1;
  width: 148px;
  transition: ease-in-out 0.25s;
  will-change: transform;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(160px, 140px) 0 ac(90px, 40px);

  .text-red-400 {
    color: var(--pink);
    transition: .3s;
  }

  a {
    color: var(--pink);
    transition: .3s;

    &:hover {
      span {
        color: var(--cyan) !important;
      }
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.section-label {
  font-size: ac(22px, 20px);
  font-weight: 500;
  line-height: 1.65;
  margin-bottom: ac(24px, 20px);
}
.swiper-buttons {
  display: flex;
  width: fit-content;
  gap: 12px;
  margin: 0 auto;
  height: auto;
  .swiper-button {
    position: relative;
    margin: 0;
    font-size: 19px;
    color: var(--white);
    width: fit-content;
    inset: revert;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    height: auto;
    &::after {
      display: none;
    }
  }
  &.swiper-buttons-vertical {
    flex-direction: column;
    height: auto;
    gap: 12px;
    margin: revert;
  }
}
.section-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: -1;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}
.social-buttons {
  display: flex;
  gap: 17px;
  color: var(--cyan);
  font-size: 32px;
  .social-btn {
    transition: transform 0.3s, opacity 0.3s;
    will-change: transform, opacity;
    &:hover {
      transform: scale(1.05);
      color: var(--white);
    }
  }
}

.simplebar-scrollbar::before {
  background-color: var(--black);
  color: var(--black);
  opacity: 1 !important;
  left: 1px;
  right: 1px;
}

.simplebar-track.simplebar-vertical {
  background: rgba(255, 255, 255, 1) !important;
  opacity: 1 !important;
  width: 5px;
  border-radius: 5px;
  right: 0px;
}

.lg-hidden {
  @media (min-width: 651px) {
    display: none !important;
  }
}
.mb-hidden {
  @media (max-width: 650px) {
    display: none !important;
  }
}

.swiper-mobile-helper {
  position: absolute;
  width: 42px;
  height: 42px;
  opacity: 0;
  pointer-events: none;
  will-change: transform, opacity;
  animation: none;
  z-index: 20;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.show {
    animation: swiperHelper 1.3s ease 2 both;
  }
}

@keyframes swiperHelper {
  0% {
    transform: translateX(0);
    opacity: 0.4;
  }
  39% {
    opacity: 0.4;
  }
  56% {
    opacity: 0.15;
  }
  77% {
    transform: translateX(-150%);
    opacity: 0;
  }
}

.border-gradient {
  --bdrsize: 1px;
  --border-radius: 12px;
  --brd-rotate: 180deg;
  position: relative;

  &-bg {
    padding: var(--bdrsize);
    position: absolute;
    border-radius: inherit;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: calc(var(--border-radius) + var(--bdrsize));

    /* hover animation */
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 1000px;
      height: 1000px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: conic-gradient(
        from 180deg at 50% 50%,
        var(--brd-color-1) 21.92282348871231deg,
        var(--brd-color-2) 38.81112188100815deg,
        var(--brd-color-2) 89.88697171211243deg,
        var(--brd-color-2) 130.18549919128418deg,
        var(--brd-color-1) 178.1249964237213deg,
        var(--brd-color-1) 202.557635307312deg,
        #43415e86 272deg,
        #3a3858dd 270.01677989959717deg,
        var(--brd-color-1) 274deg,
        var(--brd-color-1) 358.04110765457153deg
      );
      z-index: -1;
      animation: rotate360 linear infinite 8s;
    }

    /* base gradient border */
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 110%;
      height: 110%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: linear-gradient(
        var(--brd-rotate),
        var(--brd-color-1),
        var(--brd-color-2)
      );
      z-index: 0;
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  &:hover {
    --brdsize: 2px;
    .border-gradient-bg {
      &::after {
        opacity: 0;
      }
    }
  }
}

#site:not(.home-page) {
  .header {
    .menu-toggle div {
      --bg: #ffffff;
    }
    .navbar .btn-wr .btn-outline {
      --clip-point: 260px;
    }
  }
}

.snackbar-container {
  border: 1px solid var(--black);
  background: var(--cyan) !important;
  border-radius: 8px !important;
  color: var(--black) !important;
  font-family: var(--font-main);
  font-size: ac(16px, 14px);
  line-height: 1.25;
  font-weight: 400;

  p {
    font: inherit !important;
    color: inherit !important;
  }
  
  button {
    color: var(--pink) !important;
    transition: .3s;
    
    &:hover {
      color: var(--black) !important;
    }
  }
}
