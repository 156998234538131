@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;500;600;700&display=swap');
:root {
  --fz: ac(16px, 14px);
  --black: #333;
  --pink: rgba(240, 66, 141, 1);
  --purple: #7e4da7;
  --blue: #647db4;
  --cyan: #34ddd0;
  --cyan-second: #69A9A6;
  --white: #ffffff;
  --light-gray: #f3f3f3;
  --line-height: 1.5;
  --gray: #adadad;
  --gray-text: #696969;
  --cure-all: #a96a85;
  --intro-transform: 0px;

  --font-main: 'Merriweather Sans', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 992;

  --vh: calc(100vh / 100);
  --dvh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
@define-mixin mobile-menu-start-point {
  @media (max-width: 992px) {
    @mixin-content;
  }
}

@define-mixin mobile-menu-end-point {
  @media (min-width: 993px) {
    @mixin-content;
  }
}
