.our-services-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(108px, 40px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(16px, 20px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(64px, 30px);
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    grid-column-gap: ac(16px, 14px);
    min-height: ac(546px, 460px);

    @media (max-width: 700px) {
      flex-direction: column;
      grid-row-gap: 12px;
      justify-content: flex-start;
    }
  }

  &__item {
    width: ac(132px, 90px);
    padding-bottom: ac(39px, 24px);
    padding-top: 23px;
    padding-left: 19px;
    padding-right: 19px;
    background: var(--black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: 0.4s;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.border-gradient {
      --border-radius: 12px;
      --brd-color-1: #6c69b1;
      --brd-color-2: #36d7ce;
    }

    &:not(.active) {
      cursor: pointer;
    }

    &.active {
      padding-left: ac(47px, 20px);
      padding-right: ac(47px, 20px);
      padding-top: ac(40px, 23px);
      flex: 1;
      @media (min-width: 1280px) {
        margin-right: ac(24px, 0px);
      }

      &:last-child {
        margin-right: 0;

        @media (min-width: 1280px) {
          margin-left: ac(24px, 0px);
        }
      }

      .our-services-section {
        &__item-top {
          width: 100%;

          .our-services-section__item-title {
            opacity: 1;
            max-width: 100%;
          }

          .our-services-section__item-line {
            opacity: 1;
          }

          .our-services-section__item-btn {
            width: ac(48px, 40px);
            opacity: 1;
          }
        }

        &__item-content {
          opacity: 1;
        }

        &__item-icons {
          background-color: var(--pink);

          img {
            &:nth-child(1) {
              opacity: 1;
            }

            &:nth-child(2) {
              opacity: 0;
            }
          }
        }

        &__item-btn {
          &:before {
            height: 0;
          }
        }

        &__item-hidden {
          opacity: 0;
        }

        &__item-decor {
          opacity: 1;
        }
      }
    }

    @media (max-width: 700px) {
      width: 100%;

      .our-services-section {
        &__item-top {
          width: 100%;

          .our-services-section__item-title {
            opacity: 1;
            max-width: 100%;
          }

          .our-services-section__item-line {
            opacity: 1;
          }

          .our-services-section__item-btn {
            width: ac(48px, 40px);
            opacity: 1;
          }

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }

      &.active {
        .our-services-section {
          &__item-top {
            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }

          &__item-content {
            max-height: 1000px;
          }

          &__item-btn {
            border-color: var(--gray-text);
            cursor: not-allowed;

            &:before,
            &:after {
              background-color: var(--gray-text);
            }
          }
        }
      }
    }
  }

  &__item-top {
    width: ac(72px, 64px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 8px;
    transition: 0.4s;
    margin-left: auto;
    margin-right: auto;

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }

    .our-services-section__item-title {
      margin-right: ac(16px, 4px);
      transition: 0.4s;
      opacity: 0;
      max-width: 0;
      overflow: hidden;
      white-space: nowrap;
    }

    .our-services-section__item-line {
      flex: 1;
      height: 1px;
      background: var(--pink);
      display: block;
      opacity: 0;
      transition: 0.4s;
      margin-right: ac(16px, 4px);
    }

    .our-services-section__item-btn {
      opacity: 0;
      width: 0;
      overflow: hidden;

      @media (min-width: 701px) {
        border-color: var(--gray-text);
        cursor: not-allowed;

        &:before,
        &:after {
          background-color: var(--gray-text);
        }
      }
    }
  }

  &__item-icons {
    --size: ac(72px, 64px);
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    border-radius: 8px;
    background: transparent;
    transition: 0.3s;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      object-fit: contain;
      transition: 0.4s;
      z-index: 1;

      &:nth-child(1) {
        opacity: 0;
      }

      &:nth-child(2) {
        opacity: 1;
      }
    }
  }

  &__item-title {
    &:not(.headline-6) {
      font-size: ac(44px, 30px);
      line-height: 1.4;
      font-weight: 700;
    }
  }

  &__item-btn {
    --size: ac(48px, 40px);
    width: var(--size);
    height: var(--size);
    border: 1px solid var(--gray);
    position: relative;
    cursor: pointer;
    transition: 0.3s;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      content: '';
      background: var(--white);
      opacity: 1;
      transition: 0.3s;
    }

    &:after {
      width: ac(24px, 20px);
      height: 1px;
    }

    &:before {
      width: 1px;
      height: ac(24px, 20px);
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: ac(684px, 300px);
    width: ac(684px, 300px);
    transition: 0.4s;
    opacity: 0;

    @media (max-width: 1439px) {
      min-width: ac(660px, 300px);
      width: ac(660px, 300px);
    }

    @media (max-width: 700px) {
      max-width: 100%;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
    }
  }

  &__item-text {
    &.first-big-paragraph {
      p:first-child {
        width: 100%;
        max-width: ac(610px, 500px);
        margin-bottom: ac(24px, 14px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 22px);
    }
  }

  &__item-hidden {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    transition: 0.3s;
    grid-column-gap: 24px;
    bottom: ac(40px, 24px);
    grid-row-gap: 24px;
    top: 104px;

    .our-services-section__title {
      white-space: nowrap;
    }

    .our-services-section__item-line {
      flex: 1;
      width: 1px;
      background: var(--gray-text);
      display: block;
    }

    .our-services-section__item-btn {
      &:hover {
        border-color: var(--pink);
      }
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  &__item-decor {
    @mixin aspect-ratio 450, 374.06;
    width: ac(450px, 300px);
    position: absolute;
    bottom: ac(-194px, -120px);
    right: ac(-91px, -64px);
    opacity: 0;
    transition: 0.4s;
    pointer-events: none;

    img {
      object-fit: contain;
    }
  }
}
