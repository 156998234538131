.our-promises {
  padding-top: ac(90px, 48px);
  position: relative;
  overflow: visible;

  &__bg {
    @mixin aspect-ratio 1440, 1575;
    position: absolute;
    width: 100%;
    top: ac(-206px, -90px);
    opacity: 0.7;
  }

  &__wrap {
    display: flex;
    position: relative;
    z-index: 1;
    @media (max-width: 990px) {
      flex-direction: column;
      gap: 62px;
    }
  }
  &__text {
    max-width: 498px;
    width: 100%;
    margin-top: ac(-10px, 0);
    @media (max-width: 990px) {
      max-width: 768px;
    }
  }
  &__subtitle {
    margin-bottom: ac(24px, 18px);
    font-size: ac(22px, 20px);
  }
  &__title {
    font-weight: 700;
    font-size: ac(64px, 32px);
    line-height: 1.25;
    color: var(--light-gray);
    margin-bottom: ac(24px, 20px);
    @media (max-width: 990px) {
      max-width: ac(765px, 272px, 375, 990);
    }
    span {
      color: var(--cyan);
    }
  }
  &__descr {
    font-weight: 400;
    font-size: ac(28px, 20px);
    line-height: 1.42;
    color: rgba(243, 243, 243, 0.74);
    margin-bottom: ac(40px, 32px);
  }
  &__arrows {
    margin: 0;
  }

  &__slider {
    clip-path: inset(-220px -100vw 0 0px);
    padding-left: ac(64px, 32px);
    @media (max-width: 990px) {
      padding-left: 0;
    }
    .swiper {
      max-width: ac(580px, 480px, 992);
      overflow: visible;
      @media (max-width: 990px) {
        margin: 0;
      }
    }
  }
  &__card {
    border-top: 1px solid var(--gray-text);
    padding-top: ac(27px, 24px);
    &::before {
      content: '';
      border-radius: 4px;
      width: var(--before-width);
      height: ac(20px, 15px);
      position: absolute;
      top: ac(-10px, -7.5px);
      left: 0;
      backdrop-filter: blur(0.94px);
      box-shadow: inset 0 0 12px 0 rgba(52, 222, 208, 0.5),
        0 0 80px 0 rgba(52, 222, 208, 0.4), 0 0 33px 0 rgba(52, 222, 208, 0.7);
      background: #fff;
      /*transition: 0.15s;*/
    }
    /* max-width: 580px; */
    &-title {
      font-weight: 400;
      font-size: ac(26px, 18px);
      line-height: 1.53;
      color: var(--cyan);
      text-shadow: 0 0 1px #fff, 1px 0px 7px var(--cyan);
      margin-bottom: ac(15px, 14px);
      /* num */
      span {
        margin-right: ac(14px, 8px);
        font-weight: 300;
        font-size: ac(64px, 32px);
      }
    }
    &-descr {
      font-weight: 400;
      font-size: ac(34px, 22px);
      line-height: 1.48;
      color: #fff;
      margin-bottom: ac(32px, 20px);
    }

    &-action {
      padding: ac(32px, 18px);
      padding-bottom: ac(36px, 20px);
      border: 1px solid var(--cyan);
      border-radius: 16px;
      background: var(--black);
      max-height: 480px;
      min-height: ac(288px, 220px);
      pointer-events: none;
      opacity: 0;
      transition: 0.5s max-height, 0.4s opacity;
      overflow: hidden;
      @media (max-width: 990px) {
        opacity: 1;
        max-height: none;
        min-height: ac(288px, 220px);
      }
      &__title {
        margin-bottom: ac(23px, 16px);
        position: relative;
        clip-path: inset(-80vw ac(32px, 18px) -100vw -70vw);

        span {
          font-weight: 400;
          font-size: ac(26px, 18px);
          line-height: 1.53;
          color: var(--cyan);
          text-shadow: 0 0 1px #fff, 1px 0px 7px var(--cyan);
          /*padding: ac(6px, 4px) ac(16px, 12px);*/
          /*backdrop-filter: blur(0.9px);*/
          /*box-shadow: inset 0 0 12px 0 rgba(52, 222, 208, 0.5),
            0 0 128px 0 rgba(52, 222, 208, 0.5),
            0 0 33px 0 rgba(52, 222, 208, 0.7);*/
          /*background: #fff;*/
          width: fit-content;
          border-radius: 4px;
          /*color: rgba(51, 51, 51, 0.9);*/
          margin-right: 14px;
          position: relative;
          z-index: 5;
          &::before {
            content: '';
            height: 1px;
            width: 600px;
            background-color: #e2faf8;
            filter: blur(0.8px);
            position: absolute;
            top: calc(50% - 0.5px);
            right: -616px;
          }
        }
      }
      &__descr {
        font-weight: 300;
        font-size: ac(22px, 18px);
        line-height: 1.56em;
        color: #fff;
        max-height: calc(1.56em * 7);
        overflow: auto;
        scrollbar-width: thin;
      }
    }

    &:not(.swiper-slide-active) {
      &::before {
        transition: .3s;
        width: 0%;
      }
    }

    &.swiper-slide-active {
      /*&::before {
        width: 100%;
      }*/
      .our-promises {
        &__card-action {
          opacity: 1;
          max-height: 480px;
          min-height: ac(288px, 220px);
          pointer-events: auto;

          @media (max-width: 990px) {
            max-height: none;
          }
        }
      }

      &:hover {
        &:before {
          width: var(--before-width);
        }
      }
    }

    &.swiper-slide-next, &.swiper-slide-prev {
      &:before {
        transition: 1s all;
      }
    }
  }
}
