.job-details {
  margin-top: ac(-150px, -40px);
  .article-section {

  }
  &-aside {
    position: sticky;
    top: ac(85px, 70px);
    right: 0;
    width: 25.7%;
    @media (max-width: 1200px) {
      width: 36.7%;
    }
    @media (max-width: 820px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &-card {
    max-width: ac(312px, 280px, 820);
    @media (max-width: 820px) {
      max-width: 320px;
    }
    &__image {
      max-width: ac(245px, 190px, 820);
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: 820px) {
        max-width: ac(250px, 210px, 320, 820);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 0.92 / 1;
      }
      &-wrap {
        position: relative;
        overflow: hidden;
        border-radius: 12px 12px 0 0;
        margin-bottom: -10px;
        z-index: 1;
      }
      &-bg {
        img {

        }
      }
    }
    &__text {
      border: 1px solid #34ded0;
      border-bottom: 4px solid #34ded0;
      border-radius: 12px;
      padding: ac(20px, 16px);
      background: var(--black);
      position: relative;
      z-index: 2;
    }
    &__top {
      padding-bottom: ac(12px, 10px);
      margin-bottom: ac(12px, 10px);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    &__name {
      font-weight: 700;
      font-size: ac(24px, 20px);
      line-height: 1.5;
      color: #fff;
      margin-bottom: 4px;
    }
    &__position {
      font-weight: 400;
      font-size: ac(18px, 16px);
      line-height: 1.5;
      color: var(--pink);
    }
    &__socials {
      display: flex;
      flex-direction: column;
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.248;
        color: #fff;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: .2s all;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &:hover {
          text-decoration-color: #fff;
        }
      }
    }
    &__linkedin {
      margin-top: ac(16px, 12px);
      i {
        color: var(--cyan);
        transition: .3s all;
        font-size: ac(30px, 24px);
      }
    }
    
  }
}