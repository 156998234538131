.our-talent-section {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(142px, 40px);
  position: relative;
  overflow: hidden;

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__text {
    width: 100%;
    max-width: ac(602px, 420px);
    text-align: center;

    &.content-element {
      font-size: ac(20px, 16px);
      line-height: 1.7;
    }

    &:not(:last-child) {
      margin-bottom: ac(48px, 24px);
    }

    @media (max-width: 640px) {
      text-align: left;
    }
  }

  &__title {
    color: var(--cyan);
    text-align: center;

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    @media (max-width: 640px) {
      text-align: left;
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    .swiper {
      width: 100%;
      max-width: 100%;
      overflow: visible;

      @media (max-width: 551px) {
        max-width: 290px;
        margin-left: 0;
      }
    }

    @media (min-width: 768px) {
      .swiper-buttons {
        display: none;
      }
    }

    @media (max-width: 767px) {
      .swiper-buttons {
        .swiper-button {
          margin-top: ac(32px, 24px);
        }
      }
    }
  }

  @keyframes float1 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(20deg) rotateY(20deg) translateZ(20px);
    }
  }

  @keyframes float2 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateY(25deg) rotateX(-15deg) translateZ(5px);
    }
  }

  @keyframes float3 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(15deg) rotateY(-20deg) translateZ(25px);
    }
  }

  &__item {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(141px, 106px) ac(52px, 20px) ac(52px, 24px);
    height: auto;
    position: relative;
    z-index: 1;

    &:nth-child(1) {
      .our-talent-section__item-decor {
        img {
          animation: float1 4s ease-in-out infinite;
        }
      }
    }

    &:nth-child(2) {
      .our-talent-section__item-decor {
        img {
          animation: float2 5s ease-in-out infinite;
        }
      }
    }

    &:nth-child(3) {
      .our-talent-section__item-decor {
        img {
          animation: float3 6s ease-in-out infinite;
        }
      }
    }

    @media (max-width: 551px) {
      padding-bottom: 0;
    }
  }

  &__item-count {
    font-size: ac(70px, 40px);
    line-height: 1.2;
    font-weight: 700;
    color: var(--pink);

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__item-decor {
    @mixin aspect-ratio 1, 1;
    width: 49.24%;
    top: ac(75px, 24px);
    left: ac(88px, 40px);
    position: absolute;

    img {
      object-position: left;
      object-fit: contain;
    }
  }

  &__item-title {
    width: 100%;
    max-width: ac(244px, 220px);
    color: white;
  }
}
