.resources-card {
  width: ac(464px, 300px);
  max-width: 100%;
  cursor: pointer;
  display: block;
  &-thumbnail {
    width: 100%;
    border-radius: 16px;
    transform: translate(0);
    position: relative;
    overflow: hidden;
    margin-bottom: ac(36px, 28px);

    &::before {
      content: '';
      display: block;
      padding-top: 66.81%;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      /*mix-blend-mode: color;*/
      z-index: 0;
    }
    .mixed-layer {
      position: absolute;
      /*inset: 0;*/
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 656px;
      height: 598px;
      z-index: 1;
      /*background: rgba(52, 222, 208, 0.3);*/
      background-image: url('../../assets/static/blog-card-decor.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      mix-blend-mode: hard-light;
      opacity: 0.2;
      filter: blur(90px);
      &:nth-of-type(1) {
        /*height: 0;
        transition: height 0.3s 0.6s;
        will-change: height;*/
      }
      &:nth-of-type(2) {
        mix-blend-mode: color;
        will-change: mix-blend-mode;
        z-index: 2;
        opacity: 1;
      }
    }
    &-icon {
      color: var(--black);
      position: absolute;
      z-index: 5;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      background: #ffffff;
      width: ac(80px, 64px);
      height: ac(80px, 64px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ac(33px, 23px);
      line-height: 1;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.3s 0.3s;
      will-change: opacity;
      i {
        display: inline-flex;
        transition: opacity 0.3s 0s, transform 0.3s 0s;
        opacity: 0;
        transform: scale(0.5);
        will-change: opacity, transform;
      }
    }
  }
  &-tags {
    display: flex;
    gap: 11px;
    flex-wrap: wrap;
    margin-bottom: 16px;
    &-item {
      border: 10px solid;
      border-image-slice: 1;
      border-width: 1px;
      border-image-source: linear-gradient(to left, #ef418c, #42c2c8);
      font-size: 14px;
      line-height: 1.71;
      font-weight: 300;
      padding: 3px 15px;
    }
  }
  &-title {
    line-height: 1.53;
    font-size: ac(26px, 22px);
    margin-bottom: 16px;
    letter-spacing: -0.001em;
    @mixin max-line-length 3;
    @media (max-width: 768px) {
      line-height: 1.45;
    }
  }
  &-text {
    line-height: 1.625;
    font-size: 16px;
    @mixin max-line-length 6;
  }
  &:hover {
    .resources-card-thumbnail {
      .mixed-layer:nth-of-type(1) {
        height: 100%;
        transition: height 0.3s 0s;
        will-change: height;
      }
      &-icon {
        transition: opacity 0.3s 0.2s;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        i {
          transition: opacity 0.3s 0.5s, transform 0.3s 0.5s;
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
