.footer {
  padding: ac(129px, 27px) 0 61px;
  position: relative;
  overflow: hidden;
  &-waves {
    position: absolute;
    width: perc(1890);
    min-width: 977px;
    height: auto;
    left: 52.4%;
    top: ac(96px, 54px);
    transform: translate(-50%, 0);
    z-index: -1;
    max-width: revert;
  }
  &-label {
    color: var(--pink);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .title {
    font-size: ac(86px, 41px);
    margin-bottom: ac(33px, 29px);
    span {
      font-size: 51.16%;
      padding-bottom: ac(31px, 19px);
      display: inline-block;
    }
  }
  a {
    transition: color 0.3s;
    will-change: color;
    &:not([class]):hover {
      color: var(--cyan);
    }
  }
  &-cont-wr {
    display: flex;
    gap: 35px;
    justify-content: space-between;
    padding-right: ac(38px, 0px);
  }
  &-form {
    width: 572px;
    max-width: 50%;
  }
  &-info {
    display: flex;
    flex-direction: column;
    gap: 27px;
    margin-top: ac(-10px, 0px);
    font-size: 16px;
    line-height: 1.52;
  }
  &-phones {
    display: flex;
    gap: ac(40px, 14px);
    span {
      padding-left: 6px;
    }
  }
  &-logos {
    display: flex;
    align-items: center;
    padding-top: ac(43px, 7px);
    gap: 50px;
    img {
      max-width: 140px;
      max-height: 112px;
      transition: transform 0.3s;
      will-change: transform;
    }
    a {
      display: inline-block;
      width: fit-content;
    }
    a:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  &-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: ac(32px, 0px);
    gap: ac(12px, 16px) ac(12px, 7px);
    padding-top: 3px;
    a {
      font-size: 12px;
      line-height: 1;
      display: inline-flex;
      padding-right: ac(11px, 8px);
      border-right: 1px solid var(--cure-all);
      font-weight: 300;

      &:last-of-type {
        padding-right: 0;
        border: 0;
      }
    }
  }
  .form-field-wr label span {
    opacity: 1;
  }
  &-copyright {
    padding-top: ac(0px, 50px);
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 300;
    padding-left: ac(32px, 0px);
  }
  &-form-swiper-wr {
    .swiper {
      padding-bottom: ac(23px, 16px);
    }
    .swiper-buttons {
      margin-right: 0;
    }
  }
}

@media (max-width: 992px) {
  .footer .title span {
    font-size: ac(44px, 22px, 375, 992);
  }
  .footer-waves {
    left: 84.1%;
  }
}

@media (max-width: 768px) {
  .footer-cont-wr {
    flex-direction: column;
    padding-right: 0;
    max-width: 500px;
    margin: 0 auto;
    gap: 38px;
  }
  .footer {
    &-form {
      max-width: 100%;
    }
    &-copyright {
      max-width: 500px;
      margin: 0 auto 35px;
    }
    &-nav {
      max-width: 500px;
      margin: 0 auto 35px;
    }
    &-info {
      gap: 23px;
      line-height: 1.5;
    }
    &-copyright {
      padding-top: 41px;
      line-height: 1.71;
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 550px) {
  .footer-phones {
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .footer-address {
    br:nth-last-of-type(2) {
      display: none;
    }
  }
}

@media (max-width: 370px) {
  .footer-logos {
    gap: 25px;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      max-width: 43%;
      img {
        max-width: 100%;
      }
    }
  }
}
