.image-text {
  margin-top: ac(152px, 80px);
  margin-bottom: ac(108px, 62px);
  &__wrap {
    display: flex;
    gap: ac(56px, 28px);
    @media (max-width: 890px) {
      flex-direction: column;
    }
  }
  &__image {
    width: 50%;
    img {
    }
  }

  &__content {
    width: 50%;
    @media (max-width: 890px) {
      width: 100%;
    }
  }
  &__title {
    margin-bottom: ac(32px, 20px);
    font-weight: 700;
    font-size: ac(44px, 30px);
    line-height: 1.27;
    color: var(--light-gray);
    span {
      color: var(--cyan);
    }
  }
  &__descr {
    font-weight: 400;
    font-size: ac(20px, 16px);
    line-height: 1.7;
    color: var(--light-gray);
    p {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    a {
      color: var(--pink);
      text-decoration: underline;
      transition: 0.2s all;
      &:hover {
        text-decoration-color: transparent;
      }
    }

    &.body-text-2 {
      font-size: ac(20px, 16px);
      line-height: 1.7;
      font-weight: 400;

      p,
      li {
        font: inherit;
      }

      p,
      ul {
        &:not(:last-child) {
          margin-bottom: ac(20px, 16px);
        }
      }
    }
  }
  &__buttons {
    margin-top: ac(40px, 28px);
    .btn-outline {
      .btn__icon {
        margin-left: ac(12px, 10px);
        i {
          font-size: ac(22px, 20px);
          color: var(--cyan);
        }
      }
    }
  }

  &.image-text-founder {
    position: relative;
    z-index: 2;
    .image-text {
      &__wrap {
        align-items: center;

        @media (max-width: 890px) {
          flex-direction: column-reverse;
        }
      }
      &__image {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 100%;
        img {
        }
        &-wrap {
          position: relative;
          overflow: hidden;
          border-radius: 100%;
          /* max-width: ac(560px, 320px); */
          width: 46%;
          height: 100%;
          @media (max-width: 890px) {
            width: 100%;
            max-width: 480px;
          }
          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
            content: '';
            width: 350px;
            height: 350px;
            background-color: #000;
            filter: blur(280px);
            border-radius: 100%;
            opacity: 0.1;
          }
        }
        &-bg {
          position: relative;
          z-index: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  &.product-page {
    margin: 0;
    padding-bottom: ac(27px, 40px);
    padding-top: ac(4px, 40px);
    position: relative;
    z-index: 1;
    .image-text {
      &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 890px) {
          flex-direction: column;
        }
      }

      &__image-wrap {
        --arw: 600;
        --arh: 622;
        position: relative;
        width: 49.02%;
        z-index: 1;

        &::before {
          content: '';
          display: block;
          padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
        }

        &:after {
          content: '';
          position: absolute;
          top: ac(37px, 20px);
          left: ac(-96px, -20px);
          right: ac(-197px, -40px);
          bottom: ac(37px, 20px);
          background-image: url('../../assets/static/bg-gradient-elipse.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          filter: blur(100px);
          border-radius: 9999px;
          z-index: -1;
          opacity: 0.7;
        }

        @media (max-width: 890px) {
          width: 100%;
          max-width: 600px;
        }
      }

      &__image {
        @mixin aspect-ratio 422.04, 568;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70.33%;
        z-index: 1;

        img {
          object-fit: contain;
        }
      }

      &__image-text {
        font-size: ac(86px, 40px);
        line-height: 139.53%;
        font-weight: 800;
        position: absolute;
        z-index: 5;
        right: 24%;
        bottom: 12.22%;
        text-align: right;
      }

      &__content {
        width: 46.41%;
        position: relative;
        z-index: 2;

        @media (max-width: 890px) {
          width: 100%;
        }
      }
    }
  }

  &.clients-page.who-we-are-type {
    margin: 0;
    padding-bottom: ac(94px, 40px);
    padding-top: ac(94px, 40px);

    .image-text {
      &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: row-reverse;

        @media (max-width: 890px) {
          flex-direction: column;
        }
      }

      &__image-wrap {
        --arw: 600;
        --arh: 562.47;
        position: relative;
        width: 49.02%;
        filter: drop-shadow(0px 0px 40px rgba(52, 222, 208, 0.2));

        &::before {
          content: '';
          display: block;
          padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
        }

        @media (max-width: 890px) {
          width: 100%;
          max-width: 600px;
        }
      }

      &__image {
        @mixin aspect-ratio 490.71, 437.17;
        position: absolute;
        left: 9.11%;
        /*right: 9.11%;*/
        top: 11.14%;
        /*bottom: 11.14%;*/
        width: 81.78%;
        z-index: 1;
        mask-image: url('../../assets/static/mask/heart-mask.svg');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;

        img {
          object-fit: cover;
        }

        &:before,
        &:after {
          background-image: url('../../assets/static/bg-gradient.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          content: '';
          opacity: 0.3;
          z-index: 1;
        }

        &:before {
          mix-blend-mode: soft-light;
          z-index: 1;
        }

        &:after {
          mix-blend-mode: hard-light;
          z-index: 2;
          position: absolute;
          inset: 0;
        }

        /*img {
          mix-blend-mode: luminosity;
          z-index: 5;
        }*/
      }

      &__image-decor {
        @mixin aspect-ratio 600, 562.47;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        img {
          object-fit: contain;
        }
      }

      &__content {
        width: 46.41%;

        @media (max-width: 890px) {
          width: 100%;
        }
      }
    }
  }

  &.clients-page.approach-type {
    margin: 0;
    padding-bottom: ac(80px, 40px);
    padding-top: ac(80px, 40px);

    .image-text {
      &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: row-reverse;

        @media (max-width: 890px) {
          flex-direction: column;
        }
      }

      &__image-wrap {
        width: 49.02%;

        @media (max-width: 890px) {
          width: 100%;
          max-width: 600px;
        }
      }

      &__image {
        @mixin aspect-ratio 1, 1;
        width: 100%;

        img {
          object-fit: contain;
        }
      }

      &__content {
        width: 46.41%;

        @media (max-width: 890px) {
          width: 100%;
        }
      }
    }
  }
}
