.clients-say-section {
  padding-top: ac(32px, 40px);
  padding-bottom: ac(60px, 40px);
  --sw-margin: ac(58px, 30px);

  &__bg {
    position: absolute;
    top: ac(44px, 20px);
    left: 0;
    right: 0;
    bottom: ac(-160px, -50px);
    background-image: url('../../assets/static/bg-gradient.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0.1;
    filter: blur(100px);
    border-radius: 9999px;
    z-index: -1;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: var(--sw-margin);
    }
  }

  &__slider {
    width: 100%;
    position: relative;
    overflow: visible;

    .swiper {
      margin-left: 0;
      width: 100%;
      max-width: ac(1020px, 900px);
      overflow: visible;
    }

    .swiper-buttons {
      @media (min-width: 768px) {
        position: absolute;
        top: calc(var(--sw-margin) * -1);
        right: 0;
        transform: translateY(-100%);

        .arrow-btn {
          --size: ac(56px, 44px);
          width: var(--size);
          height: var(--size);
        }
      }

      @media (max-width: 767px) {
        .swiper-button {
          margin-top: ac(32px, 24px);
        }
      }
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(47px, 24px);
    padding-left: ac(43px, 20px);
    padding-right: ac(35px, 20px);
    padding-bottom: ac(47px, 24px);
    min-height: ac(498px, 200px);
    height: auto;

    &.border-gradient {
      --border-radius: ac(32px, 16px);
      --brd-color-1: #1afdf0;
      --brd-color-2: #fb3f90;
      --brd-rotate: 90deg;
    }

    &:before {
      position: absolute;
      content: '';
      inset: ac(12px, 8px);
      border-radius: ac(20px, 12px);
      background: #333;
    }
  }

  &__item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(24px, 12px);
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      margin-bottom: ac(32px, 20px);
    }
  }

  &__item-image-container {
    position: relative;
    z-index: 1;
    padding: ac(7px, 6px);
    width: ac(96px, 64px);
    min-width: ac(96px, 64px);

    &.border-gradient {
      --border-radius: 12px;
      --brd-color-1: #1afdf0;
      --brd-color-2: #fb3f90;
      --brd-rotate: 90deg;
    }
  }

  &__item-image {
    @mixin aspect-ratio 1, 1;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
  }

  &__item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(8px, 5px);
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &__item-text {
    &.subtitle-3 {
      line-height: 1.56;
    }
  }
}
