.first {
  &-wrap {
  }

  &.first-about {
    position: relative;
    padding-top: ac(265px, 180px);
    padding-bottom: ac(400px, 320px);
    .first {
      &-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: ac(382px, 230px);
        background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #333 100%);
      }
      &-bg {
        width: 100%;
        position: absolute;
        inset: 0;
        z-index: -1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-title {
        font-weight: 700;
        font-size: ac(78px, 38px);
        line-height: 1.32;
        letter-spacing: -0.01em;
        color: var(--light-gray);
        max-width: ac(836px, 530px);
        span {
          font-weight: 700;
          font-size: ac(64px, 36px);
          line-height: 1.35;
          letter-spacing: 0;
          color: #34ded0;
          text-shadow: 0 0 4px 0 rgba(52, 222, 208, 0.24);
          display: block;
        }
      }
    }
  }

  &.first-clients {
    position: relative;
    padding-top: ac(240px, 180px);
    padding-bottom: ac(166px, 90px);
    .first {
      &-bg {
        width: 100%;
        position: absolute;
        inset: 0;
        bottom: auto;
        height: 100%;
        min-height: ac(883px, 600px);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          background: linear-gradient(
            180deg,
            rgba(51, 51, 51, 0) 0%,
            rgba(51, 51, 51, 0) 90%,
            #333333 100%
          );
          content: '';
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        z-index: 2;

        .content-element {
          &.subtitle-1 {
            font-size: ac(20px, 16px);
            line-height: 1.7;
          }
        }
      }

      &-subtitle {
        &:not(:last-child) {
          margin-bottom: ac(32px, 24px);
        }
      }

      &-title {
        max-width: ac(890px, 530px);

        span {
          color: var(--cyan);
        }
      }
    }

    &.first-product {
      /* padding-top: ac(240px, 180px);
      padding-bottom: ac(166px, 90px);*/

      .first {
        &-title {
          max-width: ac(1140px, 630px);

          br {
            @media (max-width: 640px) {
              display: none;
            }
          }

          &.headline-2 {
            line-height: 1.32;
          }

          &:not(:last-child) {
            margin-bottom: ac(40px, 24px);
          }
        }
      }

      .content-element {
        width: 100%;
        max-width: ac(602px, 520px);

        &.subtitle-1 {
          line-height: 1.7;
          font-size: ac(20px, 16px);
        }
      }
    }
  }

  &.first-team {
    position: relative;
    padding-top: ac(173px, 180px);
    padding-bottom: ac(87px, 40px);
    
    @media (max-height: 670px) {
      padding-top: 80px;

      .first-title {
        margin-bottom: 10px !important;
      }

      .first-wrap {
        grid-row-gap: 20px !important;
      }
    }

    .first {
      &-bg {
        width: 100%;
        position: absolute;
        inset: 0;
        top: ac(152px, 110px);
        z-index: -1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          background: linear-gradient(
            180deg,
            #333333 0%,
            rgba(51, 51, 51, 0.8) 50.52%,
            #333333 100%
          );
          content: '';
        }
      }

      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 640px) {
          flex-direction: column;
          grid-row-gap: 30px;
        }
      }

      &-content {
        width: 44.44%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @media (max-width: 640px) {
          width: 100%;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &-subtitle {
        &:not(:last-child) {
          margin-bottom: ac(32px, 24px);
        }
      }

      &-title {
        &.headline-2 {
          line-height: 132.05%;
        }

        &:not(:last-child) {
          margin-bottom: ac(32px, 24px);
        }
      }
    }

    .content-element {
      width: 100%;
    }

    .first-content {
      width: 47.44%;

      .first-title {
        @media (max-width: 360px) {
          font-size: 37px;
        }
      }
    }

    .first-team {
      &-images {
        --arw: 1;
        --arh: 1;
        position: relative;
        width: 45.75%;
        z-index: 1;
        margin-right: ac(31px, 0px);

        &::before {
          content: '';
          display: block;
          padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
        }

        @media (max-width: 640px) {
          width: 100%;
          max-width: 400px;
        }
      }

      &-image {
        @mixin aspect-ratio 1, 1;
        position: absolute;
        overflow: hidden;

        img {
          z-index: 1;
          object-position: top;
        }

        &:nth-child(1) {
          border-radius: 50% 0px 50% 50%;
          background-image: url('../../assets/static/team-first-bg-1.jpg');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          top: 0;
          left: 0;
          width: 50%;

          &:after {
            position: absolute;
            inset: 0;
            border-radius: inherit;
            z-index: 5;
            content: '';
            box-shadow: inset 0px 0px 36px rgba(255, 255, 255, 0.4);
          }
        }

        &:nth-child(2) {
          box-shadow: inset 0px 0px 20px rgba(255, 255, 255, 0.16);
          border-radius: 50% 50% 50% 0px;
          background-image: url('../../assets/static/team-first-bg-2.jpg');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 35.71%;
          top: 7.14%;
          right: 7.14%;

          &:after {
            position: absolute;
            inset: 0;
            border-radius: inherit;
            z-index: 5;
            content: '';
            box-shadow: inset 0px 0px 20px rgba(255, 255, 255, 0.16);
          }
        }

        &:nth-child(3) {
          box-shadow: inset 0px 0px 20px rgba(255, 255, 255, 0.16);
          border-radius: 50% 0px 50% 50%;
          background-image: url('../../assets/static/team-first-bg-3.jpg');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 35.71%;
          bottom: 7.14%;
          left: 7.14%;

          &:after {
            position: absolute;
            inset: 0;
            border-radius: inherit;
            z-index: 5;
            content: '';
            box-shadow: inset 0px 0px 20px rgba(255, 255, 255, 0.16);
          }
        }

        &:nth-child(4) {
          box-shadow: inset 0px 0px 36px rgba(255, 255, 255, 0.4);
          border-radius: 50% 50% 50% 0px;
          background-image: url('../../assets/static/team-first-bg-4.jpg');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          right: 0;
          bottom: 0;
          width: 50%;

          &:after {
            position: absolute;
            inset: 0;
            border-radius: inherit;
            z-index: 5;
            content: '';
            box-shadow: inset 0px 0px 36px rgba(255, 255, 255, 0.4);
          }
        }
      }
    }
  }

  &.first-blogs {
    position: relative;
    padding-top: ac(180px, 180px);
    padding-bottom: ac(60px, 40px);

    .first {
      &-bg {
        width: 100%;
        position: absolute;
        inset: 0;
        z-index: -1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          background: linear-gradient(
            180deg,
            rgba(51, 51, 51, 0) 0%,
            rgba(51, 51, 51, 0) 85%,
            #333333 100%
          );
          content: '';
        }
      }

      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 640px) {
          flex-direction: column;
          grid-row-gap: 30px;
        }
      }

      &-content {
        width: 53.1%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: ac(-26px, 0px);

        @media (max-width: 640px) {
          width: 100%;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        .content-element {

        }
      }

      &-subtitle {
        &:not(:last-child) {
          margin-bottom: ac(32px, 24px);
        }
      }

      &-title {
        letter-spacing: -0.008em;

        &.headline-2 {
          line-height: 1.3;
        }

        &:not(:last-child) {
          margin-bottom: ac(30px, 24px);
        }
      }
    }

    .content-element {
      width: 100%;
      max-width: ac(578px, 500px);

      &.subtitle-1 {
        line-height: 1.7;
        font-size: ac(20px, 16px);
      }
    }

    .first-blogs {
      &-images {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 8px;
        width: 47.14%;
        margin-right: ac(-48px, -20px);

        @media (max-width: 640px) {
          width: 100%;
          max-width: 500px;
          margin-right: 0;
        }
      }

      &-image {
        overflow: hidden;
        flex: 1;
        border-width: 6px 0px;
        border-style: solid;
        border-color: var(--cyan);
        border-radius: 24px;

        img {
          mix-blend-mode: luminosity;
          z-index: 5;
        }

        .first-blogs-image-bg {
          position: absolute;
          inset: 0;
          z-index: 2;

          &:before,
          &:after {
            position: absolute;
            inset: 0;
            background-image: url('../../assets/static/bg-gradient.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            content: '';
            opacity: 0.3;
            z-index: 1;
          }

          &:before {
            mix-blend-mode: soft-light;
            z-index: 1;
          }

          &:after {
            mix-blend-mode: hard-light;
            z-index: 2;
          }
        }

        &:nth-child(1) {
          width: 32.41%;
          @mixin aspect-ratio 187, 379;
        }

        &:nth-child(2) {
          width: 32.41%;
          @mixin aspect-ratio 187, 466;
        }

        &:nth-child(3) {
          width: 32.41%;
          @mixin aspect-ratio 187, 688;
        }
      }
    }
  }

  &.first-candidates {
    position: relative;
    padding-top: ac(240px, 180px);
    padding-bottom: ac(80px, 32px);
    z-index: 2;
    
    @media (max-width: 540px) {
      &:before {
        position: absolute;
        inset: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));

        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        content: "";
        z-index: 1;
      }

      .first-warp {
        z-index: 15;
        position: relative;
      }
    }

    .first {
      &-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: ac(382px, 230px);
        background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #333 100%);
      }
      &-bg {
        width: 100%;
        position: absolute;
        inset: 0;
        z-index: -1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;


        }
      }
      &-bg__image {
        img {
          /*@media (max-width: 540px) {
            filter: grayscale(100%);
          }*/
        }
      }
      &-wrap {
        position: relative;
        z-index: 25;
        padding-bottom: ac(40px, 20px);
      }

      &-text {
        /*@media (max-width: 540px) {
          position: relative;
          z-index: 1;

          &:before {
            position: absolute;
            top: -10px;
            bottom: -10px;
            left: -5px;
            right: -5px;
            content: "";
            z-index: -1;
            background: inherit;
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            border-radius: 12px;

            mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
            -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
          }
        }*/
      }

      &-title {
        font-weight: 700;
        font-size: ac(64px, 32px);
        line-height: 1.32;
        letter-spacing: -0.01em;
        color: var(--light-gray);
        max-width: ac(655px, 530px);
        margin-bottom: ac(56px, 24px);
        span {
          font-weight: 700;
          font-size: ac(44px, 28px);
          line-height: 1.25;
          color: #34ded0;
          text-shadow: 0 0 4px 0 rgba(52, 222, 208, 0.24);
          display: block;
        }
      }

      &-content {
        text-align: left;
        width: 55.85%;
        font-weight: 400;
        font-size: ac(20px, 16px);
        color: var(--light-gray);
        padding: ac(64px, 32px) 0px ac(32px, 24px);
        margin-left: auto;
        margin-right: auto;

        &.content-element {
          button, a {
            display: inline;
            font: inherit;
          }

          p {
            &:last-child {
              color: var(--pink);
            }
          }
        }

        button {
          transition: .3s;

          &:hover {
            color: var(--pink);
            text-decoration-color: var(--pink);
          }
        }

        @media (max-width: 1024px) {
          width: 65%;
        }

        @media (max-width: 560px) {
          width: 100%;
        }
      }

      &-buttons {
        width: 55.85%;
        margin-left: auto;

        @media (max-width: 1024px) {
          width: 65%;
        }

        @media (max-width: 560px) {
          width: 100%;
        }
      }
    }

    .first-bg__image {
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.first-jobs {
    padding-top: ac(150px, 120px);
    padding-bottom: ac(280px, 120px);
    overflow: hidden;
    .first {
      &-descr {
        &.subtitle-1 {
          font-size: ac(20px, 16px);
          line-height: 1.7;
        }
      }
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 640px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 28px;
        }
      }
      &-text {
        max-width: ac(544px, 420px);
        padding-top: ac(40px, 0px);
      }
      &-title {
        margin-bottom: ac(20px, 18px);
        font-weight: 700;
        font-size: ac(78px, 48ox);
        line-height: 1.32;
        letter-spacing: ac(-0.624px, -0.24px);
        color: #f3f3f3;
        span {
          color: var(--cyan);
        }
      }
      &-bg {
        position: absolute;
        inset: 0;
        z-index: -1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: ac(0px, -190px, 320, 992) 0px;
        }
      }
      &-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1231px;
        z-index: -1;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(51, 51, 51, 0) 0%,
          #333 99.83%
        );
      }
      &-image {
        max-width: ac(592px, 290px, 640);
        @media (max-width: 640px) {
          max-width: 460px;
        }
        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 1 / 1;
        }
      }
    }
  }

  &.first-job-details {
    padding-top: ac(240px, 120px);
    padding-bottom: ac(280px, 120px);
    overflow: hidden;
    .cont {
      padding-right: ac(8px, 20px);
    }
    .first {
      &-text {
      }
      &-lang {
        font-weight: 700;
        font-size: ac(26px, 20px);
        line-height: 1.38462;
        color: var(--cyan);
        margin-bottom: ac(32px, 20px);
      }
      &-title {
        font-weight: 700;
        font-size: ac(64px, 38px);
        line-height: 1.25;
        color: #f3f3f3;
        margin-bottom: ac(40px, 32px);
        span {
          color: var(--cyan);
        }
      }
      &-info {
        display: flex;
        gap: ac(52px, 24px);
        margin-bottom: ac(56px, 38px);
        flex-wrap: wrap;
        &__item {
          &-top {
            font-weight: 700;
            font-size: ac(22px, 20px);
            line-height: 1.54;
            letter-spacing: 0.085px;
            color: var(--pink);
            margin-bottom: ac(16px, 12px);
            display: flex;
            align-items: center;
            i {
              margin-right: ac(8px, 6px);
              &:not(.icon-currency) {
                font-size: ac(26px, 22px);
              }
              &.icon-location {
                padding-left: ac(7px, 0);
              }
            }
          }
          &-body {
            font-weight: 400;
            font-size: ac(24px, 20px);
            line-height: 1.66;
            color: #fff;
          }
        }
      }
      &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        flex-wrap: wrap;
        gap: 24px;
      }
      &-buttons {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        @media (max-width: 460px) {
          width: 100%;
        }
        .btn,
        .btn-outline {
          min-height: ac(56px, 48px);
          @media (max-width: 460px) {
            width: 100%;
          }
        }
        .btn-white-bg {
          /*&:hover {
            background: var(--blue);
          }*/
        }
      }
      &-share {
        display: flex;
        align-items: center;
        &__title {
          font-weight: 400;
          font-size: ac(24px, 20px);
          color: #fff;
          margin-right: ac(24px, 18px);
        }
        .social-buttons {
          a {
            display: flex;
          }
        }
      }

      &-bg {
        position: absolute;
        inset: 0;
        z-index: -1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: ac(0px, -190px, 320, 992) 0px;
        }
      }
      &-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1231px;
        z-index: -1;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(51, 51, 51, 0) 0%,
          #333 99.83%
        );
      }
    }
  }
}
