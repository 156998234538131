.podcasts-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(54px, 40px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(64px, 24px);
    }
  }

  &__podcasts {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__podcast-result {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    grid-row-gap: 20px;

    &:not(:last-child) {
      margin-bottom: ac(64px, 24px);
    }
  }

  &__podcast {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    iframe {
      border-radius: 16px;
    }
  }

  + .download-section {
    margin-top: ac(54px, 40px);
  }
}
