.videos-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(64px, 40px);

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/static/bg-gradient.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0.1;
    filter: blur(100px);
    border-radius: 9999px;
    z-index: -1;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__title {
    font-size: ac(56px, 30px);
    line-height: 1;
    font-weight: 700;

    @media (max-width: 768px) {
      line-height: 1.2;
    }

    &:not(:last-child) {
      margin-bottom: ac(48px, 32px);
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__slider {
    width: 64.79%;

    .swiper {
      width: 100%;
      max-width: 100%;

      &:not(.swiper-initialized) {
        .swiper-wrapper {
          position: relative;

          .videos-section__item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            transition: opacity 0.4s;
            pointer-events: none;

            &.active {
              opacity: 1;
              position: relative;
              top: auto;
              left: auto;
              pointer-events: auto;
            }
          }
        }

        + .swiper-buttons {
          display: none;
        }
      }

      + .swiper-buttons {
        .swiper-button {
          margin-top: ac(32px, 24px);
        }
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__item-video {
    width: 100%;
    border-radius: ac(20px, 16px);
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__item-title {
    text-align: center;

    &.headline-5 {
      font-weight: 400;
    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 33.58%;
    padding-left: ac(20px, 4px);

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__list-title {
    margin-top: ac(0px, -20px);
    &.headline-5 {
      font-weight: 400;
      font-size: ac(38px, 20px);
    }
    &:not(:last-child) {
      margin-bottom: ac(24px, 14px);
    }
  }

  &__list-scroll {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% + ac(30px, 12px));
    padding-right: ac(30px, 12px);
    max-height: ac(338px, 250px);

    .scrollbar-track.scrollbar-track-y {
      width: 2px;
      background: #a96a85 !important;
      border-radius: 0;
      overflow: visible;
      right: 2px;

      .scrollbar-thumb.scrollbar-thumb-y {
        background: var(--pink);
        border-radius: 0;
        width: 6px;
        left: -2px;

        &:before {
          content: none;
        }
      }
    }

    .simplebar-track.simplebar-vertical {
      width: 2px;
      background: #a96a85 !important;
      border-radius: 0;
      overflow: visible;
      right: 2px;

      .simplebar-scrollbar {
        background: var(--pink);
        border-radius: 0;
        width: 6px;
        left: -2px;

        &:before {
          content: none;
        }
      }

      @media (min-width: 1435px) {
        right: 32px;
      }
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: ac(28px, 20px);
    }

    &:hover {
      .videos-section__toggle-icon {
        background-color: var(--cyan);
      }

      .videos-section__toggle-title {
        color: var(--cyan);
      }
    }

    @media (min-width: 768px) {
      &.active {
        max-height: 0;
        opacity: 0;
        transform: translateX(-50%) scale(0);

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__toggle-image {
    @mixin aspect-ratio 165, 94;
    width: 45.71%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    max-width: 165px;
  }

  &__toggle-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    --size: 32px;
    width: var(--size);
    height: var(--size);
    font-size: 24px;
    color: var(--pink);
    border: 1px solid var(--cyan);
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    padding-left: 2px;
  }

  &__toggle-title {
    width: 49.86%;
    transition: 0.3s;

    &.body-text-1 {
      line-height: 1.4;
    }

    @media (min-width: 1435px) {
      transform: translateX(-14px);
    }
  }
}
