.home-page {
  padding-top: calc(100vh + 100vw);
  overflow: hidden;
}

.hiring-section-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  .bg-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .gradient-layer-ellipse {
    img {
      width: perc(1203);
      height: auto;
      position: absolute;
      max-width: 1203px;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }
  }
  .gradient-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(179deg, rgba(51, 51, 51, 0) 0.86%, #333 99.27%);
    z-index: 1;
  }
  .bg-lines {
    position: absolute;
    width: perc(1500);
    height: auto;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 9.2%);
    z-index: 0;
    max-width: revert;
    min-width: 741px;
  }
}

.hiring-section {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  background: var(--black);
  position: fixed;
  left: 0;
  z-index: 1;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ac(125px, 166px);
  padding-bottom: ac(30px, 88px);
  overflow-y: auto;
  transform: translate3d(calc(100vw - var(--intro-transform)), 0, 0);
  will-change: transform;

  .mobile-brand {
    display: none;
  }

  &.absolute {
    position: absolute;
    margin-top: 100vw;
  }
  .cont {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
  }
  &-text {
    line-height: 1.6;
    font-weight: 400;
    max-width: 568px;
  }
  .section-label {
    margin-bottom: 23px;
  }
  .title {
    margin-bottom: ac(32px, 19px);
  }
  &-thumbnail {
    width: 420px;
    max-width: 36%;
    flex-shrink: 0;
    margin-right: ac(101px, 0px);
    margin-top: ac(-12px, 0px);
  }
  &-descr {
    margin-bottom: ac(40px, 33px);
    line-height: 1.7;
    font-size: ac(20px, 16px);
    @media (max-width: 992px) {
      line-height: 1.75;
    }
  }
}

.services-section {
  padding: 0 0 ac(67px, 30px);
  .section-label {
    margin-bottom: ac(16px, 21px);
  }
  .title {
    margin: 0 auto ac(63px, 40px);
    max-width: 613px;
  }
}
.services-card-wr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  max-width: 1127px;
  margin: 0 auto;
}

.services-card {
  --bdrsize: 1px;
  --border-radius: 12px;
  position: relative;
  transform: translateX(0);
  padding: 3px;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  background: #2d2d2d;
  box-shadow: 0 0 8px 0 rgba(52, 222, 208, 0.16);
  transition: 0.3s;
  min-height: 100%;

  &-bg {
    padding: var(--bdrsize);
    position: absolute;
    border-radius: inherit;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: calc(var(--border-radius) + var(--bdrsize));

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 1000px;
      height: 1000px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: conic-gradient(
        from 180deg at 50% 50%,
        #fa4090 21.92282348871231deg,
        #22ded3 38.81112188100815deg,
        #22ded3 89.88697171211243deg,
        #24d2c9 130.18549919128418deg,
        #e74088 178.1249964237213deg,
        #f0428d 202.557635307312deg,
        rgba(255, 255, 255, 0) 272deg,
        rgba(242, 93, 157, 0) 270.01677989959717deg,
        #f0428d 274deg,
        #f8408f 358.04110765457153deg
      );

      z-index: -1;
      animation: rotate360 linear infinite 8s;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 141%;
      height: 270%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: linear-gradient(90deg, #ef418c, #42c2c8);
      z-index: 0;
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  &-inner {
    width: 100%;
    background: var(--black);
    border-radius: inherit;
    padding: ac(40px, 19px) ac(39px, 24px) ac(35px, 21px);
    position: relative;
  }
  &-header {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: ac(24px, 20px);
    border-bottom: 1px solid var(--pink);
  }
  &-title {
    display: flex;
    gap: ac(17px, 14);
    align-items: center;
    font-size: ac(26px, 22px);
    font-weight: bold;
    line-height: 1.38;
    span {
      padding-bottom: ac(2px, 0px);
      padding-top: ac(0px, 12px);
    }
    img {
      flex-shrink: 0;
      width: ac(45px, 41px);
      height: auto;
      max-height: 62px;
    }
  }
  &-content {
    --line-count: 4;
    font-weight: 400;
    width: 100%;
    --fz: ac(20px, 16px);
    --line-height: 1.7;
    line-height: var(--line-height);
    font-size: var(--fz);
    /*  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));*/

    /* .simplebar-scrollbar::before {
        background-color: var(--pink);
        color: var(--pink);
        opacity: 1!important;
        left: 1px;
        right: 1px;
    }

    .simplebar-track.simplebar-vertical{
        background: rgba(255,255,255, 0.2)!important;
        opacity: 1!important;
        width: 5px;
        border-radius: 5px;
        right: -8px;
    }*/
  }
  .link-btn {
    pointer-events: none;
    position: static;
    transform: none;
    .arrow-btn {
      position: static;
      transform: none;
      i {
        transition: none;
      }
    }
  }
  &:hover {
    --bdrsize: 2px;
    * {
      pointer-events: none;
    }
    .services-card-inner {
      background: #2d2d2d;
    }
    .services-card-bg {
      &::after {
        opacity: 0;
      }
    }
    &:not(.disabled-cursor) {
      .link-btn {
        .arrow-btn {
          i {
            position: absolute;
            color: var(--white);
            left: var(--x);
            top: var(--y);
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &.disabled-cursor {
      .link-btn {
        pointer-events: all !important;
        .arrow-btn i {
          position: static !important;
          transform: none;
        }
      }
    }
  }
}

.candidate-section {
  padding: 52px 0 49px;

  &.home-page {
    padding-bottom: ac(49px, 20px);
  }

  .cont {
    display: flex;
    gap: ac(64px, 30px);
  }
  &-thumbnail {
    flex-shrink: 0;
    width: 583px;
    max-width: 50%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    mix-blend-mode: lighten;
    margin-left: ac(9px, 0px);
    align-self: flex-start;
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    flex-grow: 1;
    .buttons {
      margin-top: 40px;
    }
  }
  &-text-content {
    line-height: 1.7;
    font-size: ac(20px, 16px);
    p {
      margin-bottom: 14px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .section-label {
    margin-bottom: 24px;
  }
  .title {
    margin-bottom: ac(32px, 17px);
  }
}

.about-us-section {
  padding: ac(117px, 47px) 0 ac(60px, 40px);
  position: relative;

  &.home-page {
    padding-top: ac(60px, 20px);
  }

  .section-bg-layer {
    position: absolute;
    width: perc(1210);
    height: 100%;
    left: 0;
    top: 0;
    transform: translate(-55.8%, -45.2%);
    z-index: -1;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 30%;
      background: linear-gradient(to top, var(--black), rgba(51, 51, 51, 0));
      z-index: 1;
    }
    img {
      position: relative;
      z-index: 0;
      height: auto;
      opacity: 0.3;
      width: 100%;
    }
  }

  .cont {
    display: flex;
    gap: ac(80px, 30px);
  }
  .section-label {
    margin-bottom: 24px;
  }
  .title {
    margin-bottom: ac(48px, 18px);
  }
  &-text-content {
    line-height: 1.7;
    font-size: ac(20px, 16px);
    @media (max-width: 768px) {
      line-height: 1.625;
    }
    p {
      margin-bottom: ac(23px, 16px);
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .link-btn {
    margin-top: ac(54px, 32px);
  }
}

.motto-swiper-wr {
  position: relative;
  width: 562px;
  max-width: 50%;
  flex-shrink: 0;
  padding-top: ac(47px, 33px);

  .swiper-bg-layer {
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      width: 780px;
      max-width: 200%;
      z-index: 2;
      pointer-events: none;
    }
  }
  .swiper {
    position: relative;
    z-index: 1;
    height: 432px;
    overflow: hidden;
    margin-left: -20px;
    padding-left: 20px;

    &::before {
      content: '';
      display: block;
      z-index: 2;
      position: absolute;
      inset: 0;
      background: linear-gradient(
        180deg,
        #333 0%,
        rgba(51, 51, 51, 0.9) 16.56%,
        rgba(51, 51, 51, 0.7) 28.14%,
        rgba(51, 51, 51, 0) 33.11%,
        rgba(51, 51, 51, 0) 47.39%,
        rgba(51, 51, 51, 0) 61.31%,
        rgba(51, 51, 51, 0.6) 66.25%,
        rgba(51, 51, 51, 0.9) 80.34%,
        #333 100%
      );
      pointer-events: none;
    }
  }
  .swiper-slide {
    height: fit-content;
  }
  .swiper-slide-active {
    .motto-card-number {
      box-shadow: 0 0 16px 0 rgba(52, 222, 208, 0.54);
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }
}
.motto-swiper-title-wr {
  display: flex;
  gap: 10px;
  margin-bottom: ac(5px, 14px);
  align-items: center;
  justify-content: space-between;

  .swiper-buttons {
    opacity: 0;
    pointer-events: none;
  }
}

.motto-card {
  display: flex;
  gap: ac(24px, 16px);
  align-items: center;
  &-number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: ac(24px, 20px);
    color: var(--cyan);
    font-weight: 300;
    padding: 5px 3px;
    border: 2px solid var(--cyan);
    line-height: 1;
    position: relative;
    transition: 0.3s;
    min-width: ac(48px, 40px);
    min-height: ac(48px, 40px);
    box-shadow: 0 0 0 0 rgba(52, 222, 208, 0.64);
    background: var(--black);
    &::before,
    &::after {
      content: '';
      display: block;
      width: 2px;
      height: 24px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -100%);
      top: -10px;
      background: #69a9a6;
      transition: opacity 0.3s;
      opacity: 0;
    }
    &::after {
      top: auto;
      bottom: -10px;
      transform: translate(-50%, 100%);
    }
  }
  &-title {
    font-size: ac(28px, 22px);
    line-height: 1.71;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 500;
    @mixin max-line-length 1;
  }
  &-text {
    line-height: 1.75;
    font-size: ac(16px, 14px);
    @mixin max-line-length 3;
    @media (max-width: 768px) {
      line-height: 1.71;
    }
  }
}

.work-section {
  padding: ac(69px, 65px) 0 ac(27px, 36px);
  .section-label {
    margin-bottom: 24px;
  }
  .title {
    margin-bottom: ac(57px, 33px);
  }
}
.work-swiper-wr {
  margin-left: ac(-8px, 10px);
  margin-right: ac(-8px, 10px);
  .swiper {
    padding: 5px 0 ac(65px, 41px);
    overflow: visible;
  }
  .swiper-slide {
    height: auto;
    display: flex;
  }
}
.work-card {
  --bdrsize: 2px;
  --border-radius: 18px;
  color: var(--black);
  border-radius: 20px;
  padding: ac(32px, 20px) ac(32px, 23px) ac(48px, 30px);
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  box-shadow: 0 0 9px 0 rgba(240, 66, 141, 0.24);
  transition: border 0.3s, background 0.3s;
  border-bottom: 4px solid var(--pink);
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% + 4px);
    position: absolute;
    left: 0;
    top: 0;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background: #2d2d2d;
    border-radius: 20px;
    opacity: 0;
    z-index: 0;
    transition: opacity 0.5s;
  }
  &-label {
    color: var(--purple);
    font-weight: 700;
    font-size: ac(20px, 18px);
    margin-bottom: ac(20px, 11px);
    position: relative;
    z-index: 3;
  }
  &-title {
    font-size: ac(26px, 22px);
    font-weight: 700;
    line-height: 1.38;
    margin-bottom: ac(31px, 21px);
    position: relative;
    z-index: 3;
    @mixin max-line-length 3;
    @media (max-width: 768px) {
      line-height: 1.45;
    }
  }
  &-info {
    display: flex;
    flex-wrap: wrap;
    gap: 19px 5px;
    color: var(--pink);
    font-size: ac(18px, 16px);
    font-weight: 500;
    margin-bottom: ac(28px, 19px);
    padding-left: 6px;
    position: relative;
    z-index: 3;
    li {
      display: inline-flex;
      align-items: center;
      gap: ac(9px, 5px);
      font-size: 21px;
      min-width: ac(139px, 114px);
    }
    i {
      font-size: 21px;
      &.icon-currency {
        font-size: 79%;
      }
      &.icon-location {
        min-width: 22px;
      }
    }
    span {
      line-height: 1;
      display: inline-block;
      font-size: ac(18px, 16px);
    }
  }
  &-text {
    line-height: 1.65;
    margin-bottom: ac(37px, 18px);
    font-size: ac(16px, 14px);
    position: relative;
    z-index: 3;
    @mixin max-line-length 3;
  }
  .link-btn-simple {
    margin-top: auto;
    position: relative;
    z-index: 3;
  }

  &-bg {
    padding: var(--bdrsize);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% + 4px);
    z-index: 2;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: calc(var(--border-radius) + var(--bdrsize));
    pointer-events: none;
    opacity: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 1000px;
      height: 1000px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: conic-gradient(
        from 180deg at 50% 50%,
        #f0428d 0deg,
        #23d6cc 46.2776917219162deg,
        #f0428d 129.25155401229858deg,
        #22d7cd 177.12432861328125deg,
        #f0428d 225.54015398025513deg,
        rgba(45, 45, 45, 0) 360deg
      );
      z-index: -1;
      animation: rotate360 linear infinite 8s;
    }
  }

  &:hover {
    color: var(--white);
    background: transparent;
    &::before {
      opacity: 1;
    }
    .work-card-label {
      color: var(--cyan);
    }
    .work-card-bg {
      opacity: 1;
    }
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.gradient-text {
  color: #fff;
  text-shadow: 0px 0px 4px rgba(52, 222, 208, 0.24);
  font-style: normal;
  font-weight: 700;
  /*line-height: 56px;*/

  background: url('../../public/images/text-gradient.png') no-repeat center /
    cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 5s linear infinite;
}

.resources-section {
  padding: ac(52px, 47px) 0 ac(120px, 48px);
  &-caption {
    display: flex;
    gap: 35px;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 64px;
  }

  .title {
  }
  &-motto {
    flex-grow: 1;
    max-width: 375px;
    color: var(--gray);
    font-size: ac(20px, 18px);
    line-height: ac(32px, 26px);
  }

  &.blog-page {
    padding-top: ac(64px, 40px);
    padding-bottom: ac(60px, 40px);

    .resources-section-caption {
      padding-bottom: ac(64px, 32px);
    }

    .resources-swiper-wr {
      position: relative;
      z-index: 1;

      .swiper-controls {
        @media (min-width: 768px) {
          position: absolute;
          right: 0;
          top: ac(-64px, -32px);
          transform: translateY(-100%);
          margin-top: 0;

          .swiper-button {
            margin-top: 0;
          }
        }
      }

      .swiper {
        @media (min-width: 768px) {
          padding-bottom: 0;
        }
      }
    }
  }
}

.resources-swiper-wr {
  .swiper {
    overflow: visible;
    padding-bottom: ac(64px, 40px);
  }
  .swiper-slide {
    width: fit-content;
    display: flex;
    height: auto;
    max-width: 100%;
  }
  .swiper-controls {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ac(26px, 15px, 320, 1440);
  }
}

.subscribe-section {
  padding: ac(157px, 40px) 0 ac(113px, 48px);
  &-cont-wr {
    max-width: 661px;
  }
  .title {
    font-size: ac(64px, 36px);
    margin-bottom: 24px;
  }
  &-motto {
    font-size: ac(24px, 18px);
    line-height: ac(40px, 28px);
    font-weight: 500;
    margin-bottom: ac(59px, 31px);
  }
  .mobile-img {
    display: none;
  }
}
.form-field-wr {
  display: flex;
  flex-direction: column;
  gap: ac(23px, 16px);
  label {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 21px;
    span {
      opacity: 1;
      font-size: ac(16px, 14px);
    }
  }
  .btn-outline {
    align-self: flex-end;
  }


  .subscribe-form__bottom {
    display: flex;
    justify-content: space-between;

    .meta-checkbox {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0;
      width: 67%;

      @media (max-width: 540px) {
        width: 100%;
      }

      input:checked ~ .meta-checkbox__box {
        background-color: var(--cyan);
      }

      .meta-checkbox__box {
        border-color: var(--black);
        min-width: ac(32px, 24px);
        min-height: ac(32px, 24px);

        &:hover {
          background: var(--cyan);
          border-color: var(--cyan);
        }
      }

      .meta-checkbox__text {
        width: 100%;

        a {
          color: var(--cyan);

          &:hover {
            color: var(--blue);
          }
        }

        @media (max-width: 540px) {
          width: 100%;
        }

        @media (max-width: 375px) {
          font-size: 13px;

          a {
            color: var(--black);

            &:hover {
              color: var(--pink);
            }
          }
        }
      }
    }

    @media (max-width: 540px) {
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;

      .btn-outline {
        align-self: auto;
      }
    }
  }
}

@mixin mobile-menu-start-point {
  .hiring-section {
    padding-top: 38px;
    height: auto;
    overflow: hidden;
    .mobile-brand {
      display: block;
      margin-bottom: 97px;
      width: 327px;
      max-width: 100%;
      min-height: 40px;
    }
    .cont {
      flex-direction: column;
      gap: 0;
      position: relative;
      z-index: 1;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 200px;
      z-index: 0;
      background: linear-gradient(
        to top,
        rgba(51, 51, 51, 1),
        rgba(51, 51, 51, 0)
      );
    }
  }
  .hiring-section-bg {
    position: absolute;
    width: 100%;
    height: auto;
    overflow: visible;
    .gradient-layer {
      background: linear-gradient(
        to top,
        rgba(51, 51, 51, 1),
        rgba(51, 51, 51, 1),
        rgba(51, 51, 51, 0)
      );
      width: 100%;
      height: 50%;
      top: auto;
      bottom: 0;
    }
    .gradient-layer-ellipse {
      background: url('../../public/images/ellipse-blur-mobile.png') no-repeat
        center/ cover;
      filter: blur(157.5px);
      opacity: 0.56;
      width: 396px;
      height: 749px;
      top: -16px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 2;
      will-change: filter;
      img {
        display: none;
      }
    }
    .bg-lines {
      position: relative;
      left: 75%;
      top: 0;
      transform: translate(-50%, -7.8%);
      z-index: 0;
    }
  }
  .hiring-section-thumbnail {
    max-width: 64%;
    margin-top: 64px;
  }
}

@media (max-width: 768px) {
  .services-card-wr {
    grid-template-columns: 1fr;
    gap: 14.8px;
  }
  .services-card {
    max-width: 375px;
    width: 100%;
    margin: 0 auto;
    &-content {
      line-height: 1.625;
      br {
        display: none;
      }
    }
  }
  .candidate-section {
    padding-top: 7px;
    .cont {
      flex-direction: column;
      gap: 50px;
    }
    &-thumbnail {
      width: 327px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .about-us-section {
    .cont {
      flex-direction: column;
      gap: 28px;
    }
  }
  .motto-swiper-wr {
    margin: 0 auto;
    max-width: 100%;
    .swiper {
      height: 420px;
    }
  }
  .motto-swiper-title-wr {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 39px;

    .swiper-buttons {
      display: inline-flex;
      gap: 7px;
    }
  }
  .motto-card-text {
    -webkit-line-clamp: 5;
  }
  .resources-section-caption {
    flex-direction: column;
    align-items: stretch;
    gap: 19px;
    padding-bottom: 40px;
  }
}

@media (max-width: 450px) {
  .subscribe-section {
    .desktop-img {
      display: none;
    }
    .mobile-img {
      display: block;
    }
  }

  .services-card {
    &-title {
      img {
        margin-bottom: -1px;
      }
    }
    .link-btn {
      span:not([class]) {
        font-size: 10px;
      }
    }
  }
  .candidate-section {
    &-text {
      .btn {
        margin-top: 27px;
        width: 100%;
      }
    }
  }
  .work-card-info {
    padding-left: 6px;
    gap: 19px 12px;
    i {
      font-size: 21px;
      &.icon-currency {
        font-size: 73%;
      }
    }
    li {
      display: inline-flex;
      align-items: center;
    }
    span {
      display: inline-block;
      line-height: 1;
    }
  }
  .resources-swiper-wr .swiper-controls {
    justify-content: space-between;
    width: 100%;
    .btn {
      flex-grow: 1;
    }
  }
  .subscribe-section .title {
    padding-right: ac(90px, 80px, 320, 375);
    line-height: 1.25;
    margin-bottom: 16px;
  }
}

@media (max-width: 370px) {
  .work-card {
    &-title {
      font-size: 18px;
    }
    &-info {
      font-size: 16px;
      gap: 10px;
      margin-bottom: 10px;
      li {
        min-width: revert;
        font-size: 16px;
      }
      i {
        font-size: 16px;
      }
      span {
        font-size: 14px;
      }
    }
  }
}

@keyframes rotate360 {
  0% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0);
  }
}
