

input,
textarea {
  @mixin transparent-bg-input;
  background: transparent;
  border-bottom: 1px solid var(--gray);
  font-weight: 500;
  font-size: ac(18px, 16px);
  padding-bottom: 12px;
  color: var(--white);
  border-radius: 0;
  &::placeholder {
    color: inherit;
    font-size: inherit;
  }

  &.name-placeholder-input {
    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
  }
}
.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(32px, 16px);
    min-width: ac(32px, 16px);
    height: ac(32px, 16px);
    border: 1px solid var(--pink);
    margin-right: ac(14px, 6px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--pink);
      background-color: var(--cyan);

      .check-clip,
      .circle-clip {
        background-color: var(--white);
      }
    }

    .check-clip,
    .circle-clip {
      width: 70%;
      height: 70%;
      background-color: var(--white);
      opacity: 0;
      transition: 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 4px;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    line-height: 1.4;

    a {
      font: inherit;
      color: var(--pink);
      text-decoration: underline;
      transition: 0.3s;

      &:hover {
        color: var(--cyan);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    background-color: var(--pink);
    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
    }
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  select {
    opacity: 0;
    height: ac(56px, 48px);
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;
    &__inner {
      border: none;
      border-bottom: 1px solid var(--gray);
      border-radius: 0px;
      outline: none;
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 5px 19px !important;
      padding-left: 0px!important;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      cursor: pointer;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--light-gray);
      height: ac(50px, 48px);
      min-height: ac(50px, 48px);

      /* &:hover {
        box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
      } */
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__inner {
          border-radius: 0px 0px 0 0;
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          border-top: none;
          margin-top: 0;
          border-radius: 0 0 0px 0px;
        }
      }

      &.is-flipped {
        .choices__inner {
          border-radius: 0 0 0px 0px;
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 0;
          border-bottom: none;
          border-radius: 0px 0px 0 0;
        }
      }
    }

    &__item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__placeholder {
      color: var(--light-gray);
      opacity: 1;
    }

    &__list {
      padding: 0;
      background-color: var(--gray-text);
      .choices__item {
        padding-right: ac(16px, 12px);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--light-gray);
        &.is-selected {
          color: var(--black);
        }
      }
      &--single {
        background: transparent;
      }

      /*&--multiple {
        color: var(--cl-dark-blue);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }*/

      &--dropdown {
        .choices__item {
          color: var(--light-gray);

          &:hover {
            color: var(--cyan) !important;
          }
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        border: none;
        content: '\e913';
        /* border-bottom: 1px solid var(--cl-navy-80); */
        /* border-right: 1px solid var(--cl-navy-80); */
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: ac(25px, 18px);
        top: 50%;
        transition: all 0.15s ease-in-out;
        /* width: 5px; */

        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
      
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.is-open {
      &:after {
        transform: rotate(180deg) translate(-16px, -10px);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;

      &:not(.is-selected) {
        color: var(--cl-primary);
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      /* border: 1px solid var(--cl-aqua); */
      z-index: 20;
      border-radius: 0px;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      /* border: 2px solid var(--cl-lilac-500); */
      border-radius: 0px;
      background: transparent;
      color: var(--cl-primary);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-main);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }

  &.sort-type {
    .choices {
      .choices__inner {
        /* border-color: rgba(53, 53, 63, 0.3); */
        background-color: var(--cl-white);
      }

      .choices__list {
        background-color: var(--cl-white);
      }

      .choices__list.choices__list--single {
        .choices__item.choices__item--selectable {
          /*padding-left: 120px !important;
          position: relative;*/

          &:before {
            display: inline-block;
            content: 'Sort by ‒';
            margin-right: 4px;
          }
        }
      }

      &[data-type*='select-one'],
      &[data-type*='select-multiple'] {
        &:after {
          /* border-color: var(--cl-charcoal); */
        }
      }
    }
  }

  &.profile-type {
    .choices {
      .choices__inner {
        /* border-color: rgba(53, 53, 63, 0.3); */
        background-color: var(--cl-white);
      }

      .choices__list {
        background-color: var(--cl-white);
      }

      .choices__list.choices__list--single {
        .choices__item.choices__item--selectable {
          /*padding-left: 120px !important;
          position: relative;*/

          color: var(--cl-charcoal);
          opacity: 0.8;
        }
      }
    }
  }
}

.meta-range {
  display: flex;
  align-items: center;
  gap: 8px;
  label {
    min-width: fit-content;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.625;
    color: #fff;
    span {

    }
  }
  input[type=range] {
    height: 18px;
    -webkit-appearance: none;
    /* margin: 10px 0; */
    width: 100%;
    border: none;
    padding-bottom: 0;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #34DED0;
    border-radius: 0px;
    border: 0px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 32px;
    background: #34DED0;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8.5px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #34DED0;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #34DED0;
    border-radius: 0px;
    border: 0px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 32px;
    background: #34DED0;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #34DED0;
    border: 0px solid #000000;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #34DED0;
    border: 0px solid #000000;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 32px;
    background: #34DED0;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #34DED0;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #34DED0;
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  /*label,
  .label {
    font-family: var(--font-main);
    color: var(--cl-leaf);
    font-size: ac(14px, 12px);
    font-weight: 600;
    padding-bottom: ac(13px, 6px);
    cursor: pointer;
  }*/

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(17px, 10px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(13px, 10px);
    }
  }

  &__input {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(17px, 16px);
    line-height: normal;
    font-weight: 700;
    font-family: var(--font-main);
    color: var(--white);
    border: 2px solid var(--white);
    border-radius: 28px;
    background: var(--black);
    transition: all 0.3s ease;
    padding: 4px ac(28px, 18px);
    cursor: pointer;
    position: relative;
    height: ac(44px, 40px);
    text-transform: none;

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
      color: var(--white);
      transition: .3s;
    }

    &:hover {
      border-color: var(--cyan);
      background-color: transparent;
      color: var(--cyan);

      span {
        color: var(--cyan);
      }
    }
  }

  &__file-name {
    max-width: calc(100% - ac(122px, 114px));
    @mixin max-line-length-one;
    font-family: var(--font-second);
    font-size: ac(18px, 15px);
    font-weight: 300;
    color: var(--cyan);
  }

  &__info {
    font-size: ac(14px, 12px);
    line-height: normal;
    font-weight: 300;
    font-family: var(--font-second);
    font-style: italic;
    color: var(--white);
  }

  &.dark-bg-type {
    .meta-upload-file {
      &__input {
        border-color: var(--black);
        background: var(--white);
        color: var(--black);

        span {
          color: var(--black);
        }

        &:hover {
          border-color: var(--pink);
          /*background-color: var(--cyan);
          color: var(--pink);

          span {
            color: var(--pink);
          }*/
        }
      }

      &__info {
        color: var(--black);
      }

      &__file-name {
        color: var(--pink);
      }
    }
  }
}

.double-range-container {
  height: 72px;
}

.double-range {
  padding-right: 15px;
  margin-bottom: 30px;
  height: 2px;
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: var(--cyan-second);

  .noUi-connect {
    background: var(--cyan);
    margin-right: -4px;
  }

  .noUi-handle {
    background: var(--cyan);
    width: 18px;
    height: 18px;
    border: 2px solid white;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: none;

    &:before,
    &:after {
      content: none;
    }
  }

  .noUi-horizontal .noUi-handle {
    top: -9px;
  }
}

.double-range-inputs {
  &__container {
    margin-top: -10px;
    display: flex;
    align-items: center;
  }

  .double-range-input {
    border-radius: 0;
    width: 38%;
    line-height: 1.2;
    height: auto;
    transition: .3s;
    background: transparent;
    border-bottom: 1px solid var(--gray);
    font-weight: 500;
    font-size: ac(18px, 16px);
    padding-bottom: 10px;
    padding-top: 10px;
    color: var(--white);
    margin-left: 2px;

    &:focus {
      border-color: var(--cyan);
    }
  }

  span {
    font-weight: 500;
    font-size: ac(18px, 16px);
    line-height: 1;
    color: var(--white);

    &:not(.unit) {
      margin: 0 5px;
    }

    &.unit {
      margin-right: 2px;
    }
  }
}