.contact-first-section {
  padding-top: ac(228px, 160px);
  padding-bottom: ac(240px, 100px);
  position: relative;

  @media (min-width: 1600px) {
    padding-bottom: 285px;
  }

  @media (min-width: 2000px) {
    padding-bottom: 310px;
  }

  @media (min-width: 2300px) {
    padding-bottom: 350px;
  }

  &__bottom-decor {
    @mixin aspect-ratio 1440, 271.5;
    position: absolute;
    bottom: -2px;
    left: -2px;
    right: -2px;
  }

  &__bg {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 5;
  }

  &__decor {
    @mixin aspect-ratio 1500.18, 1247;
    position: absolute;
    top: ac(-545px, -300px);
    right: ac(-921px, -300px);
    width: 122.56%;
    z-index: -1;

    @media (max-width: 768px) {
      top: auto;
      bottom: -90px;
      min-width: 540px;
    }
  }

  &__title {
    &.headline-3 {
      line-height: 125%;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 16px);
    }
  }

  &__subtitle {
    &.headline-5 {
      line-height: 147.37%;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 16px);
    }
  }

  &__text {
    padding-top: ac(32px, 10px);
    width: 100%;
    max-width: ac(499px, 350px);

    &:not(:last-child) {
      margin-bottom: ac(35px, 22px);
    }
  }
}
