h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: ac(78px, 41px);
  line-height: 1.34;
  @media (max-width: 370px) {
    font-size: 37px;
  }
}

h2 {
  font-size: ac(44px, 30px);
  font-weight: bold;
  line-height: 1.27;
  @media (max-width: 992px) {
    line-height: 1.33;
  }
  @media (max-width: 374px) {
    font-size: 24px;
  }
}

h3 {
  font-size: ac(25px, 22px);
}

h4 {
  font-size: ac(20px, 18px);
}

h5 {
  font-size: ac(28px, 20px);
}

h6 {
  font-size: ac(24px, 18px);
}

p {
}

li {
}

a {
  display: inline;
}

.headline-1 {
  font-size: ac(86px, 48px);
  line-height: 1.2;
  font-weight: 700;
}

.headline-2 {
  font-size: ac(78px, 41px);
  line-height: 1.32;
  font-weight: 700;
  letter-spacing: -0.008em;
}

.headline-3 {
  font-size: ac(64px, 36px);
  line-height: 1.5;
  font-weight: 700;
}

.headline-4 {
  font-size: ac(44px, 30px);
  line-height: 127.27%;
  font-weight: 700;

  @media (max-width: 380px) {
    font-size: 28px;
    letter-spacing: -0.001em;
  }
}

.headline-5 {
  font-size: ac(38px, 26px);
  line-height: 1.48;
  font-weight: 400;
}

.headline-6 {
  font-size: ac(26px, 22px);
  line-height: 1.38;
  font-weight: 700;
}

.subtitle-1 {
  font-size: ac(24px, 20px);
  line-height: 1.54;
  font-weight: 400;
}

.subtitle-2 {
  font-size: ac(26px, 20px);
  line-height: 1.54;
  font-weight: 400;
}

.subtitle-3 {
  font-size: ac(18px, 16px);
  line-height: 1.77;
  font-weight: 400;
}

.button-text {
  font-size: ac(16px, 15px);
  line-height: 1.62;
  font-weight: 400;
}

.body-text-1 {
  font-size: ac(20px, 16px);
  line-height: 1.7;
  font-weight: 400;

  &.first-big-paragraph {
    p {
      &:first-child {
        font-size: ac(24px, 20px);
        line-height: 1.54;
        font-weight: 700;

        &:not(:last-child) {
          margin-bottom: ac(24px, 20px);
        }
      }
    }
  }
}

.body-text-2 {
  font-size: ac(16px, 14px);
  line-height: 1.6;
  font-weight: 400;
}

.caption-1 {
  font-size: ac(16px, 14px);
  line-height: 1.6;
  font-weight: 400;
  font-style: italic;
}

.caption-2 {
  font-size: 14px;
  line-height: 1.71;
  font-weight: 300;
  font-style: italic;
}

.caption-3 {
  font-size: 12px;
  line-height: 1.67;
  font-weight: 300;
  font-style: italic;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  a {
    font: inherit;
    display: inline;
    text-decoration: underline;
    color: var(--pink);
    transition: 0.3s;

    &:hover {
      color: var(--cyan);
    }
  }

  h4 {
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  li,
  p {
    /*Not Design*/
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &.article-type {
    font-size: ac(20px, 16px);
    line-height: 1.56;
    font-weight: 400;

    p,
    li {
      font-size: ac(20px, 16px);
      line-height: 1.56;
      font-weight: 400;

      &:not(:last-child) {
        margin-bottom: ac(17px, 12px);
      }
    }

    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: ac(16px, 12px);
      }
    }

    p,
    ul,
    ol {
      + h2 {
        padding-top: ac(40px, 16px);
      }

      + h3 {
        padding-top: ac(40px, 16px);
      }

      + img {
        margin-top: ac(56px, 20px);
      }

      + h4 {
        padding-top: ac(16px, 8px);
      }
    }

    img {
      border-radius: ac(20px, 16px);
      width: 100%;
      object-fit: cover;
      max-height: 600px;
      overflow: hidden;

      &:not(:last-child) {
        margin-bottom: ac(56px, 32px);
      }
    }

    h2 {
      font-size: ac(44px, 30px);
      line-height: 127.27%;
      font-weight: 700;

      &:not(:last-child) {
        margin-bottom: ac(22px, 20px);

        &:first-child {
          margin-bottom: ac(32px, 20px);
        }
      }

      + h4 {
        padding-top: ac(20px, 16px);
      }
    }

    h3 {
      font-size: ac(32px, 26px);
      line-height: 175%;
      font-weight: 700;

      &:not(:last-child) {
        margin-bottom: ac(20px, 16px);
      }

      @media (max-width: 768px) {
        line-height: 1.3;
      }

      + h4 {
        padding-top: ac(20px, 16px);
      }
    }

    h4 {
      font-size: ac(26px, 20px);
      line-height: 138.46%;
      font-weight: 700;

      &:not(:last-child) {
        margin-bottom: ac(16px, 14px);
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      list-style: none;

      li {
        position: relative;
        padding-left: ac(33px, 18px);

        &:before {
          position: absolute;
          top: ac(11.5px, 9px);
          left: ac(11px, 0px);
          --size: ac(8px, 6px);
          width: var(--size);
          height: var(--size);
          content: '';
          border-radius: 50%;
          background: var(--pink);
        }

        &:not(:last-child) {
          margin-bottom: ac(16px, 12px);
        }
      }
    }
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--white);
  }
}
