.get-in-section {
  margin-top: ac(60px, 40px);
  padding-top: ac(120px, 40px);
  position: relative;
  overflow: hidden;
  z-index: 1;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ac(580px, 360px);
    clip-path: inset(1px 0 2px 0);
    transform: translateZ(0);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__content {
    width: 51.06%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(48px, 24px);
    }

    @media (max-width: 951px) {
      width: 100%;
    }
  }

  &__title {
    &.headline-2 {
      line-height: 1.32;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__text {
    &.subtitle-2 {
      line-height: 153.85%;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 951px) {
      flex-direction: column;
      grid-row-gap: 30px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 51.06%;
    border-radius: ac(20px, 16px);
    padding: ac(40px, 24px) ac(56px, 20px);
    background: white;
    color: var(--black);

    @media (max-width: 951px) {
      width: 100%;
    }
  }

  &__form-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(32px, 16px);
    grid-row-gap: ac(40px, 20px);

    &:not(:last-child) {
      margin-bottom: ac(40px, 20px);
    }

    .form-field-wr {
      span {
        font-size: 14px;
        color: var(--pink);
        opacity: 1;
      }

      input,
      textarea {
        border-color: var(--black);
        color: var(--black);
        @mixin transparent-bg-input var(--black);

        &::placeholder {
          color: var(--gray-text);
        }
      }
    }

    @media (max-width: 451px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: ac(42px, 20px);

    @media (max-width: 451px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__form-checkbox {
    flex: 1;

    .meta-checkbox__text {
      font-size: 12px;
      line-height: 18px;
      color: var(--gray-text);
      letter-spacing: -0.02em;

      a {
        color: var(--pink);

        &:hover {
          color: var(--black);
        }
      }
    }
  }

  &__form-buttons {
    width: 174px;

    .btn-wr {
      width: 100%;

      .btn-outline {
        width: 100%;
      }
    }

    @media (max-width: 451px) {
      width: 100%;
    }
  }

  &__join {
    margin-top: ac(4px, 0px);
    width: 41.01%;
    border: 1px solid var(--cyan);
    border-radius: ac(20px, 16px);
    padding: ac(39px, 24px) ac(39px, 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--black);

    @media (max-width: 1200px) {
      width: 46%;
    }

    @media (max-width: 951px) {
      width: 100%;
    }
  }

  &__join-top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: ac(50px, 22px);
    }
  }

  &__join-title {
    font-size: ac(38px, 26px);
    line-height: 1.48;
    font-weight: 700;
    color: var(--cyan);
    max-width: ac(280px, 200px);
  }

  @keyframes float6 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(-5deg) rotateY(-15deg) translateZ(35px);
    }
  }

  &__join-image {
    @mixin aspect-ratio 86, 95;
    width: ac(86px, 64px);
    min-width: ac(86px, 64px);
    animation: float5 5s ease-in-out infinite;
  }

  &__join-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: ac(20px, 12px);
    grid-row-gap: 16px;

    @media (max-width: 360px) {
      flex-direction: column;

      .btn-outline {
        width: 100%;
      }
    }

    .btn-outline {
      flex: 1;

      .btn__text {
        font-size: ac(26px, 20px);
        white-space: nowrap;
      }
    }
  }
}
