.text-video-section {
  padding-top: ac(96px, 40px);
  padding-bottom: ac(64px, 40px);
  position: relative;
  z-index: 5;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;

    @media (max-width: 901px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 30px;
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40.69%;
    position: relative;
    z-index: 2;

    @media (max-width: 901px) {
      width: 100%;
    }
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__title {
    &.headline-4 {
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 30px);
    }
  }

  &__text {
    &.body-text-1 {
      line-height: 170%;
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__video-container {
    width: 54.74%;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      top: ac(-96px, 20px);
      left: ac(-108px, -20px);
      right: ac(-26px, -20px);
      bottom: ac(-186px, -20px);
      background-image: url('../../assets/static/bg-gradient-elipse.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      filter: blur(100px);
      border-radius: 9999px;
      z-index: -1;
      opacity: 0.7;
    }

    @media (max-width: 901px) {
      width: 100%;
      max-width: 600px;
    }
  }

  &__video {
    border-radius: ac(20px, 16px);
    overflow: hidden;
    width: 100%;
  }

  &__buttons {
    position: relative;
    z-index: 5;
  }
}
