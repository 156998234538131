.our-values {
  margin-top: ac(-180px, -80px);
  overflow: hidden;
  &__nav {
    margin-bottom: ac(64px, 32px);
    display: flex;
    justify-content: center;
  }
  &__text {
    max-width: ac(712px, 640px);
    font-weight: 400;
    font-size: ac(20px, 16px);
    line-height: 1.66;
    text-align: center;
    color: var(--light-gray);
  }

  &__wrap {
    .swiper {
      margin-left: 0;
      overflow: visible;
      @media (max-width: 1200px) {
        max-width: ac(395px, 280px, 320, 768);
      }
    }
    .swiper-wrapper {
      align-items: stretch;
    }
  }

  @keyframes float3 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(5deg) rotateY(-15deg) translateZ(20px);
    }
  }

  @keyframes float4 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateY(-10deg) rotateX(10deg) translateZ(25px);
    }
  }

  @keyframes float5 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(15deg) rotateY(5deg) translateZ(30px);
    }
  }

  @keyframes float6 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(-5deg) rotateY(-15deg) translateZ(35px);
    }
  }

  &__card {
    padding: ac(32px, 18px);
    padding-top: ac(50px, 28px);
    max-width: 395px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto !important;
    background: #343334;
    --brd-color-1: #6c69b1;
    --brd-color-2: #36d7ce;

    &:nth-child(1) {
      .our-values__card-image {
        img {
          animation: float6 6s ease-in-out infinite;
          transition: animation-duration 3s ease;
        }
      }
    }

    &:nth-child(2) {
      .our-values__card-image {
        img {
          animation: float5 5s ease-in-out infinite;
          transition: animation-duration 3s ease;
        }
      }
    }

    &:nth-child(3) {
      .our-values__card-image {
        img {
          animation: float4 4s ease-in-out infinite;
          transition: animation-duration 3s ease;
        }
      }
    }

    @media (max-width: 1200px) {
      max-width: ac(395px, 280px, 320, 768);
    }

    &-title {
      font-weight: 400;
      font-size: ac(38px, 28px);
      line-height: 1.47;
      text-align: center;
      color: var(--light-gray);
      margin-bottom: ac(50px, 32px);
    }

    &-image {
      position: relative;
      margin-bottom: ac(64px, 30px);
      display: flex;
      justify-content: center;

      &__decor {
        position: absolute;
        /* width: 156px;
        height: 20px; */
        left: 50%;
        transform: translateX(-50%);
        bottom: -58px;
        max-height: 93px;
      }
      img {
      }
    }
    &-descr {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.62rem;
      text-align: center;
      color: #f3f3f3;
      overflow: auto;
      max-height: calc(9 * 1.62rem);
      scrollbar-width: thin;
    }
  }
}
