.filters-slider {
  width: 100%;
  max-width: 100%;
  position: relative;

  .swiper {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    overflow: visible;
  }

  &__helper {
    top: -10px;
    right: -10px;

    @media (min-width: 769px) {
      display: none;
    }
  }

  &__item {
    width: min-content;
    input {
      display: none;

      &:checked {
        + .filters-slider__item-toggle {
          color: var(--cyan);
          border-color: var(--white);
        }
      }
    }
  }

  &__item-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 11px;
    padding-bottom: 10px;
    border-bottom: 1px solid #696969;
    padding-left: ac(24.5px, 16px);
    padding-right: ac(24.5px, 16px);
    font-size: 16px;
    line-height: ac(26px, 22px);
    white-space: nowrap;

    transition: 0.3s;
    color: var(--white);
    cursor: pointer;

    &:hover {
      color: var(--gray);
      border-color: var(--pink);
    }
  }
}
