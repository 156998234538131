:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 80);

    .success-title {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
    }

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }

  &__block {

    &.popup-apply-now {
      padding-top: ac(40px, 32px);
      padding-left: ac(48px, 20px);
      padding-right: ac(48px, 20px);
      padding-bottom: ac(64px, 32px);
      border-radius: ac(32px, 20px);
      box-shadow: 0px 0px 104px 0px #00000080;
      border: 1px solid var(--black);
      background: var(--black);
      overflow: hidden;

      .popup-get-in-touch__bg {
        position: absolute;
        inset: -1px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .popup__btn-close {
        position: absolute;
        z-index: 10;
        font-size: 24px;
        top: ac(28px, 12px);
        right: ac(28px, 12px);
        color: var(--white);

        i {
          color: inherit;
        }

        &:hover {
          color: var(--cyan);
        }
      }

      .popup__title {
        position: relative;
        z-index: 1;
        color: var(--white);

        &.headline-4 {
          font-size: ac(32px, 24px);
          line-height: 1.38;
          font-weight: 700;
          text-transform: none;

          span {
            color: var(--cyan);
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(40px, 24px);
        }
      }

      .popup__content {
        position: relative;
        width: calc(100% + 10px);
        padding-right: 10px;

        @media (min-width: 768px) {
          max-height: calc(var(--vh, 1vh) * 60);
        }

        @media (max-width: 551px) {
          max-height: calc(var(--vh, 1vh) * 56);
        }

        .simplebar-track.simplebar-vertical {
          width: 2px;
          background: #a96a85 !important;
          border-radius: 0;
          overflow: visible;
          right: 2px;
          top: ac(50px, 40px);

          .simplebar-scrollbar {
            background: var(--pink);
            border-radius: 0;
            width: 6px;
            left: -2px;

            &:before {
              content: none;
            }
          }
        }
      }

      .form-popup__form-checkbox {
        .meta-checkbox {
          .meta-checkbox__box {
            &:hover {
              background: none;


              .check-clip {
                opacity: 40%;
              }
            }
          }
        }
      }

      .form-popup__form-bottom {
        @media (max-width: 540px) {
          flex-direction: column;
        }
      }
    }

    &.popup-get-in-touch {
      padding-top: ac(40px, 32px);
      padding-left: ac(48px, 20px);
      padding-right: ac(48px, 20px);
      padding-bottom: ac(64px, 32px);
      border-radius: ac(32px, 20px);
      box-shadow: 0px 0px 104px 0px #00000080;
      border: 1px solid var(--black);
      background: var(--black);
      max-width: ac(622px, 580px);
      overflow: hidden;

      .popup-get-in-touch__bg {
        position: absolute;
        inset: -1px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .popup__btn-close {
        position: absolute;
        z-index: 10;
        font-size: 24px;
        top: ac(36px, 12px);
        right: ac(36px, 12px);
        color: var(--white);

        i {
          color: inherit;
        }

        &:hover {
          color: var(--cyan);
        }
      }

      .popup__title {
        position: relative;
        z-index: 1;
        color: var(--white);
        &.headline-4 {
          text-transform: none;
          font-size: ac(44px, 30px);
          line-height: 127.27%;
          font-weight: 700;
        }

        &:not(:last-child) {
          margin-bottom: ac(40px, 24px);
        }
      }

      .popup__content {
        position: relative;
        width: calc(100% + 10px);
        padding-right: 10px;

        .form-popup {
          @media (min-width: 768px) {
            max-height: calc(var(--vh, 1vh) * 60);
          }

          @media (max-width: 551px) {
            max-height: calc(var(--vh, 1vh) * 40);
          }

          @media (max-width: 360px) {
            max-height: calc(var(--vh, 1vh) * 50);
          }

          .simplebar-track.simplebar-vertical {
            width: 2px;
            background: #a96a85 !important;
            border-radius: 0;
            overflow: visible;
            right: 2px;

            .simplebar-scrollbar {
              background: var(--pink);
              border-radius: 0;
              width: 6px;
              left: -2px;

              &:before {
                content: none;
              }
            }
          }
        }
      }

      &.popup-download {
        max-width: ac(800px, 620px);
        padding-top: ac(80px, 40px);
        padding-left: ac(64px, 20px);
        padding-right: ac(64px, 20px);
        padding-bottom: ac(64px, 40px);

        .form-popup {
          &__form-grid {
            &:not(:last-child) {
              margin-bottom: ac(24px, 20px);
            }
          }

          &__form-bottom {
            justify-content: flex-end;
          }
        }
      }
    }

    &.team-popup {
      padding: 0;
      background: var(--black);
      max-width: ac(1080px, 900px);
      width: 95%;
      border-radius: ac(40px, 20px);
      border: ac(12px, 3px) solid #c3c2c2;
      overflow: hidden;
      position: relative;

      &:after {
        position: absolute;
        top: ac(44px, 20px);
        left: 0;
        right: 0;
        bottom: ac(-160px, -50px);
        background-image: url('../../assets/static/bg-gradient.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        opacity: 0.1;
        filter: blur(100px);
        border-radius: 9999px;
        z-index: -1;
        content: '';
      }

      .team-popup {
        &__container {
          width: 100%;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
        }

        &__card {
          width: ac(320px, 300px);
          min-width: ac(320px, 300px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          background-image: url('../../assets/static/popup-team-card-bg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          z-index: 1;

          position: relative;

          &:after {
            top: 0;
            right: 0;
            bottom: 0;
            width: 1px;
            content: '';
            z-index: 10;
            background: linear-gradient(
              180deg,
              rgba(52, 222, 208, 1) 0%,
              rgba(240, 66, 141, 1) 100%
            );
            position: absolute;
          }

          @media (max-width: 640px) {
            display: none;
          }
        }

        &__card-image {
          margin-top: 20px;
          @mixin aspect-ratio 1, 1;
          width: calc(100% - 40px);
          margin-left: auto;
          margin-right: auto;
          position: relative;
          z-index: 1;
          mask-image: url('../../assets/static/mask/team-card-popup-mask.svg');
          mask-size: 100% 100%;
          mask-repeat: no-repeat;

          img {
            object-fit: contain;
            object-position: top;
          }
        }

        &__card-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding: ac(32px, 20px) ac(32px, 20px) ac(36px, 20px) ac(36px, 20px);
          flex: 1;
        }

        &__card-title {
          font-size: ac(26px, 22px);
          line-height: 1.38;
          font-weight: 700;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        &__card-subtitle {
          font-size: ac(18px, 16px);
          line-height: 1.77;
          font-weight: 400;
          color: var(--cyan);

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }

        &__card-socials {
          margin-top: auto;
          grid-column-gap: ac(12px, 10px);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
        }

        &__card-social {
          display: flex;
          justify-content: center;
          align-items: center;
          --size: 40px;
          width: var(--size);
          height: var(--size);
          border: 1px solid var(--cyan);
          border-radius: 5px;
          background: transparent;
          color: var(--cyan);
          font-size: 40px;
          transition: 0.3s;

          &:hover {
            background-color: var(--cyan);
            color: var(--pink);
          }
        }

        &__content-container {
          width: calc(100% - ac(320px, 300px));
          position: relative;

          padding-right: ac(56px, 22px);
          padding-top: ac(40px, 24px);
          padding-left: ac(40px, 20px);
          padding-bottom: 0;
          z-index: 1;

          @media (min-width: 641px) {
            .team-popup__card-image {
              display: none;
            }

            .team-popup__card-content {
              display: none;
            }
          }

          @media (max-width: 640px) {
            width: 100%;

            .team-popup__card-image {
              max-width: 390px;
              width: calc(100% - 24px);
              background: transparent;
            }

            .team-popup__card-content {
              border-top: 1px solid rgba(5, 5, 5, 0.1);
              padding-left: 0;
              padding-right: 0;
              background: transparent;
            }
          }
        }

        &__content {
          width: calc(100% + ac(16px, 12px));
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-right: ac(16px, 12px);
          overflow: visible;

          position: relative;

          &:after {
            position: absolute;
            left: -5%;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              180deg,
              #333333 0%,
              rgba(51, 51, 51, 0) 100%
            );
            transform: matrix(1, 0, 0, -1, 0, 0);
            height: ac(170px, 80px);
            opacity: 1;
            transition: 0.3s;
            pointer-events: none;
            content: '';
            mask-image: linear-gradient(
              to right,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 1) 5%,
              rgba(0, 0, 0, 1) 100%
            );
          }

          &:hover {
            &:after {
              opacity: 0;
            }
          }

          .simplebar-track.simplebar-vertical {
            width: 2px;
            background: #a96a85 !important;
            border-radius: 0;
            overflow: visible;
            right: ac(2px, 4px);
            top: 90px;
            bottom: 20px;

            .simplebar-scrollbar {
              background: var(--pink);
              border-radius: 0;
              width: 6px;
              left: -2px;

              &:before {
                content: none;
              }
            }
          }

          @media (min-width: 641px) {
            min-height: 100%;
            max-height: 55.44vh;
          }
        }

        &__title {
          font-size: ac(44px, 30px);
          line-height: 1.4;
          font-weight: 700;
          color: var(--cyan);
          text-transform: none;

          &:not(:last-child) {
            margin-bottom: ac(24px, 20px);
          }
        }

        &__text {
          width: 100%;
          font-size: ac(20px, 16px);
          line-height: 1.56;
          font-weight: 400;
          color: var(--white);

          p,
          ul,
          ol {
            &:not(:last-child) {
              margin-bottom: ac(12px, 14px);
            }

            &:last-child {
              padding-bottom: ac(24px, 20px);
            }

            + h2 {
              padding-top: ac(16px, 12px);
            }

            + h3 {
              padding-top: ac(16px, 12px);
            }

            + h4 {
              padding-top: ac(16px, 12px);
            }
          }

          h2 {
            font-size: ac(44px, 30px);
            line-height: 1.4;
            font-weight: 700;
            color: var(--cyan);

            &:not(:last-child) {
              margin-bottom: ac(20px, 16px);
            }
          }

          h3 {
            font-size: ac(26px, 22px);
            line-height: 1.38;
            font-weight: 700;
            color: var(--cyan);

            &:not(:last-child) {
              margin-bottom: ac(20px, 16px);
            }
          }

          h4 {
            font-size: ac(24px, 20px);
            line-height: 1.38;
            font-weight: 700;
            color: var(--cyan);

            &:not(:last-child) {
              margin-bottom: ac(20px, 16px);
            }
          }
        }

        &__btn-close {
          position: absolute;
          z-index: 10;
          font-size: 24px;
          top: ac(56px, 16px);
          right: ac(20px, 12px);
          color: var(--white);

          @media (max-width: 1440px) {
            top: ac(56px, 34px);
          }

          @media (max-width: 540px) {
            top: ac(56px, 16px);
          }

          i {
            color: inherit;
          }

          &:hover {
            color: var(--cyan);
          }
        }
      }
    }
  }

  .form-popup {
    &__form-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: ac(20px, 16px);
      grid-row-gap: ac(32px, 20px);

      &:not(:last-child) {
        margin-bottom: ac(32px, 20px);
      }

      .form-field-wr {
        span {
          font-size: 14px;
          color: var(--cyan);
          opacity: 1;
        }
      }

      @media (max-width: 451px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }

    &__form-bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 451px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
      }
    }

    &__form-checkbox {
      width: calc(100% - 140px);
      max-width: 298px;

      .meta-checkbox__text {
        font-size: 12px;
        line-height: 18px;
        color: #f3f3f3;
        letter-spacing: -0.02em;

        a {
          color: var(--cyan);

          &:hover {
            color: var(--white);
          }
        }
      }

      @media (max-width: 451px) {
        width: 100%;
      }
    }

    &__form-buttons {
      .btn-wr {
        width: 100%;

        .btn-outline {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 48px;
          min-width: 126px;
        }
      }

      @media (max-width: 451px) {
        width: 100%;
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}
