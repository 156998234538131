.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(48px, 40px);
  min-width: ac(48px, 40px);
  max-width: ac(48px, 40px);
  background: transparent;
  border: 1px solid transparent;

  i {
    font-size: 16px;
    color: var(--cyan);
    transition: color 0.3s ease;
  }

  &.pagination-btn-disabled {
    cursor: not-allowed;
    color: var(--gray-text);
  }

  &:hover {
    &:not(.pagination-btn-disabled) {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }

  &.pagination-next {
    margin-left: ac(16px, 8px);
  }

  &.pagination-prev {
    margin-right: ac(16px, 8px);
  }
}

.pagination-num {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(48px, 40px);
  min-width: ac(48px, 40px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    transition: all 0.3s ease;
  }

  &.active {
    cursor: not-allowed;
    border-color: var(--cyan);

    span {
      color: var(--white);
    }
  }

  &.pointer-events-none {
    pointer-events: none;
    width: ac(48px, 28px);
    min-width: ac(48px, 28px);

    span {
      color: var(--gray-text);
    }
  }

  &:hover {
    &:not(.active) {
      color: var(--cyan);
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ac(0px, 2px);
}
