.testimonials {
  padding-top: ac(73px, 48px);
  padding-bottom: ac(120px, 84px);
  position: relative;
  overflow: hidden;

  &-blur {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: ac(1203px, 800px);
    width: 100%;
    filter: blur(20px);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: ac(64px, 42px);
    gap: ac(32px, 20px);
    flex-wrap: wrap;
    &__block {
      max-width: ac(550px, 480px);
    }
  }
  &-subtitle {
    margin-bottom: ac(24px, 20px);
  }
  &-title {
    @media (max-width: 414px) {
      font-size: 31px;
    }

    @media (max-width: 390px) {
      font-size: 29px;
    }

    @media (max-width: 375px) {
      font-size: 28px;
    }

    @media (max-width: 360px) {
      font-size: 23px;
    }

    span {
      color: var(--cyan);
    }
  }
  &-descr {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.62;
    color: var(--light-gray);
    max-width: ac(395px, 360px);
    @media (max-width: 890px) {
      max-width: 576px;
    }
    p {
    }
  }

  &-wrap {
    position: relative;
    .swiper {
      overflow: visible;
      max-width: ac(810px, 516px, 640);
      .swiper-wrapper {
        min-height: 472px;
        align-items: center;
      }
    }
  }
  &-card {
    padding: 0 ac(48px, 12px, 640);
    padding-top: ac(56px, 40px);
    padding-bottom: ac(64px, 42px);
    position: relative;
    transition: 0.3s all;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 2px;
      width: 100%;
      background: linear-gradient(270deg, #3bcfcb 0%, #f0428d 100%);
      box-shadow: 0 0 5px 0 rgba(52, 222, 208, 0.31);
      transition: 0.2s all;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    &__content {
      opacity: 1;
      max-height: 340px;
      transition: 0.8s all;
      overflow: hidden;
      &-icon {
        --size: ac(56px, 38px);
        max-width: fit-content;
        min-width: var(--size);
        height: var(--size);
        margin-bottom: ac(32px, 20px);
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__descr {
      margin-bottom: ac(32px, 20px);
      line-height: 1.54em;
      max-height: calc(6 * 1.54em);
      overflow: auto;
      scrollbar-width: thin;
      p {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 0;
      transition: 0.4s all;
      gap: 12px;
      @media (max-width: 440px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }
    &__user {
      &-name {
        margin-bottom: ac(12px, 10px);
        transition: 0.3s all;
        transition-delay: 0.1s;
      }
      &-position {
        color: var(--cyan);
        transition: 0.3s all;
        transition-delay: 0.1s;
      }
    }
    &__logo {
      max-width: ac(188px, 120px);
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
  &-arrows {
    margin-top: ac(48px, 20px);
  }

  .swiper-slide {
    &:not(.swiper-slide-active) {
      padding-top: ac(64px, 42px);
      &::before,
      &::after {
        height: 1px;
      }
      .testimonials-card {
        &__content {
          opacity: 0;
          max-height: 0px;
        }
        &__user {
          &-name {
            font-size: ac(26px, 22px);
          }
          &-position {
            font-size: 16px;
            color: var(--pink);
          }
        }
      }
    }
  }

  &.clients-page {
    padding-bottom: ac(60px, 40px);

    .testimonials-swiper-wr {
      position: relative;

      @media (min-width: 952px) {
        .swiper-buttons {
          margin-top: 0;
          position: absolute;
          right: 0;
          top: ac(-64px, -42px);
          transform: translateY(-100%);

          .swiper-button {
            margin-top: 0;
          }
        }
      }
    }
  }
}
