.blog-first-section {
  padding-top: ac(240px, 180px);
  padding-bottom: ac(40px, 20px);

  &__bg {
    position: absolute;
    inset: 0;

    &:after {
      position: absolute;
      inset: 0;
      z-index: 3;
      background: linear-gradient(
        180deg,
        rgba(51, 51, 51, 0.5) 0%,
        rgba(51, 51, 51, 0) 30%,
        rgba(51, 51, 51, 0) 85%,
        #333333 100%
      );
      content: '';
    }
  }

  &__decor {
    position: absolute;
    inset: 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__image-container {
    width: ac(720px, 400px);
    position: absolute;
    top: 0;
    right: 0;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 1) calc(100% - 5px),
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 0.9;

    @media (max-width: 451px) {
      opacity: 0.7;
    }
  }

  &__image {
    @mixin aspect-ratio 720, 698;
    width: 100%;
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) calc(100% - 100px),
      rgba(0, 0, 0, 0) 100%
    );

    &:before {
      z-index: 2;
      position: relative;
      background: var(--cyan);
      mix-blend-mode: color;
    }

    &:after {
      position: absolute;
      inset: 0;
      z-index: 5;
      background: var(--black);
      content: '';
      opacity: 0.2;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 10;
    gap: ac(56px, 32px);
    padding-bottom: ac(56px, 40px);
    clip-path: inset(-1px -1px -1px -1px);
    transform: translateZ(0);

    &:before {
      position: absolute;
      width: 1224px;
      bottom: 0;
      height: 1px;
      background-image: url('../../assets/static/blogs-line.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translateY(50%);
      left: 0;
      content: '';
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: ac(912px, 650px);
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(19px, 14px);
    grid-row-gap: 4px;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__subtitle {
    font-size: ac(26px, 20px);
    line-height: 1.1;
    font-weight: 700;
  }

  &__time {
    font-size: ac(20px, 18px);
    line-height: 1.1;
    font-weight: 700;
  }

  &__title {
    &.headline-3 {
      line-height: 1.25;
    }

    &:not(:last-child) {
      margin-bottom: ac(37px, 24px);
    }
  }

  &__tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ac(12px, 10px);

    .resources-card-tags-item {
      @media (min-width: 1200px) {
        font-size: 16px;
        min-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(56px, 32px);
    }
  }

  &__buttons {
  }

  &__share {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(24px, 16px);

    @media (min-width: 768px) {
      position: absolute;
      bottom: ac(56px, 40px);
      right: 0;
    }

    .social-buttons {
      gap: 13px;
    }

    .social-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__share-title {
    font-size: ac(24px, 18px);
    line-height: 1.1;
    font-weight: 400;
  }
}
