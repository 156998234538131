.our-traditions {
  padding-top: ac(160px, 80px);
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: ac(83px, 42px);
  }
  &__subtitle {
    margin-bottom: ac(16px, 12px);
  }
  &__title {
    span {
      color: var(--cyan);
    }
  }

  &__wrap {

  }
  .swiper {
    overflow: visible;
    margin: 0;
    @media (max-width: 992px) {
      max-width: ac(576px, 420px, 768, 992);
    }
    @media (max-width: 576px) {
      max-width: ac(420px, 270px, 320, 576);
    }
    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: ac(20px, 16px);
      @media (max-width: 992px) {
        display: flex;
        gap: 0;
      }
    }
  }

  @keyframes float3 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(5deg) rotateY(-15deg) translateZ(20px);
    }
  }

  @keyframes float4 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateY(-10deg) rotateX(10deg) translateZ(25px);
    }
  }

  @keyframes float5 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(15deg) rotateY(5deg) translateZ(30px);
    }
  }

  @keyframes float6 {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(-5deg) rotateY(-15deg) translateZ(35px);
    }
  }


  &__card {
    display: flex;
    align-items: flex-start;
    gap: ac(42px, 20px);
    min-height: 298px;

    &:nth-child(1) {
      .our-traditions__card-image {
        img {
          animation: float6 6s ease-in-out infinite;
        }
      }
    }

    &:nth-child(2) {
      .our-traditions__card-image {
        img {
          animation: float5 5s ease-in-out infinite;
        }
      }
    }

    &:nth-child(3) {
      .our-traditions__card-image {
        img {
          animation: float4 4s ease-in-out infinite;
        }
      }
    }

    &:nth-child(4) {
      .our-traditions__card-image {
        img {
          animation: float3 3s ease-in-out infinite;
        }
      }
    }

    @media (max-width: 576px) {
      flex-direction: column;
    }

    &-image {
      max-width: ac(156px, 120px, 992);
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: float5 3s ease-in-out infinite;
      }
      &__wrap {
        position: relative;
        padding-left: ac(40px, 0px, 992);
        margin-top: ac(35px, 0px);
        min-width: ac(219px, 126px, 992);
      }
      &__decor {
        width: ac(240px, 156px);
        position: absolute;
        bottom: -70px;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 576px) {
          opacity: 0;
        }
      }
    }

    &-text {
      max-width: ac(327px, 284px);
    }
    &-num {
      font-weight: 700;
      font-size: ac(36px, 24px);
      line-height: 1;
      color: var(--pink);
      margin-bottom: ac(20px, 16px);
    }
    &-title {
      font-weight: 700;
      font-size: ac(26px, 20px);
      line-height: 1.384;
      color: var(--light-gray);
      margin-bottom: 12px;
    }
    &-descr {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.625;
      letter-spacing: 0.01em;
      color: var(--light-gray);

      &[data-simplebar] {
        width: calc(100% + 10px);
        max-height: calc((16px * 1.625) * 5);
        padding-right: 10px;
        transition: .3s;

        .simplebar-vertical {
          .simplebar-scrollbar {
            background: var(--purple) !important;

            &:before {
              background: var(--purple);
            }
          }
        }
      }
    }
  }
}